import React from 'react';
import ReverieJournalApp from './ReverieJournalApp';
import { QueryClient, QueryClientProvider } from 'react-query'

const queryClient = new QueryClient()

function App() {
  return (
    <div className="App">
    <QueryClientProvider client={queryClient}>
      <ReverieJournalApp />
    </QueryClientProvider>
    </div>
  );
}

export default App;