import React, { useState, useEffect, useRef } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./ui/dialog"
import { Input } from "./ui/input"
import { Label } from "./ui/label"
import { Button } from "./ui/button"
import { Slider } from "./ui/slider"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { CreditCard, AlertCircle, CheckCircle, XCircle, Loader2, Settings, Download, AlertTriangle, Save, Sparkle, Sparkles, Settings2, CalendarCheck2, Cog, Calendar, Eclipse, WandSparkles } from 'lucide-react';
import { RadioGroup, RadioGroupItem } from "./ui/radio-group"
import { Card, CardContent, CardDescription, CardHeader, CardTitle } from "./ui/card";
import moment from 'moment-timezone';
import { set } from 'lodash';

const DayButton = ({ day, selected, onClick }) => (
  <button
    type="button"
    className={`w-8 h-8 rounded-full text-sm font-medium transition-colors ${
      selected
         ? 'bg-gray-700 text-white hover:bg-gray-600'
         : 'bg-gray-200 text-gray-700 hover:bg-gray-300 dark:bg-black dark:text-gray-200 dark:hover:bg-gray-600'
    }`}
    onClick={() => onClick(day)}
  >
    {day.charAt(0)}
  </button>
);

const SettingsModal = ({ isOpen, onClose, handleResubscribe }) => {
  const [name, setName] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [defaultTime, setDefaultTime] = useState('');
  const [callTimes, setCallTimes] = useState({
    Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: ''
  });
  const [selectedDays, setSelectedDays] = useState([]);
  const [subscriptionStatus, setSubscriptionStatus] = useState('Active');
  const [error, setError] = useState('');
  const [isCustomSchedule, setIsCustomSchedule] = useState(false);
  const [timeZone, setTimeZone] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isHoveringCancel, setIsHoveringCancel] = useState(false);
  const [isConfirmingCancel, setIsConfirmingCancel] = useState(false);
  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [isLoading, setIsLoading] = useState(true);
  const [isSubscribed, setIsSubscribed] = useState(false);
  const [currentTime, setCurrentTime] = useState('');
  const [saveStatus, setSaveStatus] = useState('idle'); // 'idle', 'loading', 'success'
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [originalPhoneNumber, setOriginalPhoneNumber] = useState('');
  const [isExporting, setIsExporting] = useState(false);
  const [exportError, setExportError] = useState('');
  const [exportSuccess, setExportSuccess] = useState(false);
  const [activeSubscription, setActiveSubscription] = useState(true);
  const [selectedPromptType, setSelectedPromptType] = useState('default');
  const [formattingLevel, setFormattingLevel] = useState(null);
  const [countryCode, setCountryCode] = useState('+1');
  const phoneInputRef = useRef(null);
  const [fullPhoneNumber, setFullPhoneNumber] = useState('');

  const countryCodeMap = {
    '+1': { 
      flag: '🇨🇦/🇺🇸',
      name: 'Canada/US', 
      regex: /^[2-9]\d{9}$/, 
      format: (num) => num.length > 0 ? `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`.trim() : '' 
    },
    '+44': { 
      flag: '🇬🇧', 
      name: 'UK', 
      regex: /^[1-9]\d{9,10}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`.trim() : '' 
    },
    '+49': { 
      flag: '🇩🇪', 
      name: 'Germany', 
      regex: /^[1-9]\d{7,14}$/, 
      format: (num) => num 
    },
    '+61': { 
      flag: '🇦🇺', 
      name: 'Australia', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 4)} ${num.slice(4)}`.trim() : '' 
    },
    '+82': { 
      flag: '🇰🇷', 
      name: 'South Korea', 
      regex: /^[1-9]\d{7,9}$/, 
      format: (num) => num 
    },
    '+31': { 
      flag: '🇳🇱', 
      name: 'Netherlands', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`.trim() : '' 
    },
    '+33': { 
      flag: '🇫🇷', 
      name: 'France', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5, 7)} ${num.slice(7)}`.trim() : '' 
    },
    '+34': { 
      flag: '🇪🇸', 
      name: 'Spain', 
      regex: /^[6-9]\d{8}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`.trim() : '' 
    },
    '+91': { 
      flag: '🇮🇳', 
      name: 'India', 
      regex: /^[1-9]\d{9,10}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 5)} ${num.slice(5)}`.trim() : '' 
    },
  };

  useEffect(() => {
    const updateCurrentTime = () => {
      if (timeZone) {
        const now = moment().tz(timeZone);
        setCurrentTime(now.format('h:mmA'));
      }
    };

    updateCurrentTime();
    const timer = setInterval(updateCurrentTime, 60000); // Update every minute

    return () => clearInterval(timer);
  }, [timeZone]);
  

  // const ApiURL = 'http://localhost:5000';
  const ApiURL = 'https://api.callreverie.com';


  
  const days = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'];

  useEffect(() => {
    if (isOpen) {
      fetchUserSettings();
    }
  }, [isOpen]);

  const detectCountryCode = (number) => {
    // Remove any non-digit characters from the start of the number
    const cleanNumber = number.replace(/^\D+/, '');
    
    // Check if it's a US/Canada number (starts with 1)
    if (cleanNumber.startsWith('1') && cleanNumber.length === 11) {
      return { countryCode: '+1', phoneNumber: cleanNumber.slice(1) };
    }
    
    // Check for other country codes (2 or 3 digits)
    for (let i = 2; i <= 3; i++) {
      const potentialCode = '+' + cleanNumber.slice(0, i);
      if (Object.keys(countryCodeMap).includes(potentialCode)) {
        return { countryCode: potentialCode, phoneNumber: cleanNumber.slice(i) };
      }
    }
    
    // If no match found, default to +1 and use the whole number
    return { countryCode: '+1', phoneNumber: cleanNumber };
  };


  const fetchUserSettings = async () => {
    setIsLoading(true);
    setIsHoveringCancel(false);
    setIsConfirmingCancel(false);
    try {
      const response = await fetch(ApiURL + '/api/user/settings', {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch user settings');
      }
      const data = await response.json();
      setName(data.name || '');
      setPhoneNumber(data.phone || '');
      setOriginalPhoneNumber(data.phone || '');
      setFullPhoneNumber(data.phone || '');
      setSelectedPlan(data.selectedPlan || 'monthly');
      setIsSubscribed(data.isSubscribed || false);
      setActiveSubscription(data.activeSubscription);
      setSelectedPromptType(data.selectedPromptType || 'default');
      setFormattingLevel(data.formattingLevel); // Add this line to set the formatting level
      // Set time zone and then process call times
      const userTimeZone = data.timeZone || moment.tz.guess();
      setTimeZone(userTimeZone);
      
      processCallTimes(data.callTimes, userTimeZone);

      const fullPhoneNumber = data.phone || '';
      const { countryCode: detectedCountryCode, phoneNumber: detectedPhoneNumber } = detectCountryCode(fullPhoneNumber);
      
      setCountryCode(detectedCountryCode);
      setPhoneNumber(detectedPhoneNumber);


      if (data.isSubscribed) {
        if (data.activeSubscription === false) {
          setSubscriptionStatus('Cancelling');
        } else {
          setSubscriptionStatus('Active');
        }
      } else {
        setSubscriptionStatus('Cancelled');
      }
    } catch (error) {
      console.error('Error fetching user settings:', error);
      setError('Failed to load settings. Please try again.');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (fullPhoneNumber) {
      const phoneMatch = fullPhoneNumber.match(/^\+(\d+)(\d+)$/);
      if (phoneMatch) {
        const extractedCountryCode = `+${phoneMatch[1]}`;
        const extractedPhoneNumber = phoneMatch[2];
        setCountryCode(extractedCountryCode);
        setPhoneNumber(extractedPhoneNumber);
      } else {
        setPhoneNumber(fullPhoneNumber);
      }
    }
  }, [fullPhoneNumber]);

  

  const processCallTimes = (rawCallTimes, tz) => {
    const convertedCallTimes = {};
    const updatedSelectedDays = [];

    if (rawCallTimes && typeof rawCallTimes === 'object') {
      Object.entries(rawCallTimes).forEach(([utcDay, times]) => {
        if (Array.isArray(times) && times.length > 0) {
          times.forEach(time => {
            const localMoment = moment.utc(`${utcDay} ${time}`, 'ddd HH:mm').tz(tz);
            const localDay = localMoment.format('ddd');
            const localTime = localMoment.format('HH:mm');

            if (!convertedCallTimes[localDay]) {
              convertedCallTimes[localDay] = [];
            }
            convertedCallTimes[localDay].push(localTime);
            
            if (!updatedSelectedDays.includes(localDay)) {
              updatedSelectedDays.push(localDay);
            }
          });
        }
      });
    }
    

    setCallTimes(convertedCallTimes);
    setSelectedDays(updatedSelectedDays);

    // Check if all times are the same
    const allTimes = Object.values(convertedCallTimes).flat();
    const allSameTime = allTimes.every(time => time === allTimes[0]);
    
    if (allSameTime && allTimes.length > 0) {
      setDefaultTime(allTimes[0]);
      setIsCustomSchedule(false);
    } else {
      setDefaultTime('');
      setIsCustomSchedule(allTimes.length > 0);
    }
  };

  const handleDayToggle = (day) => {
    setCallTimes(prev => {
      const newCallTimes = { ...prev };
      if (newCallTimes[day] && newCallTimes[day].length > 0) {
        newCallTimes[day] = [];
      } else {
        newCallTimes[day] = [defaultTime || '12:00'];
      }
      return newCallTimes;
    });

    setSelectedDays(prev => {
      if (prev.includes(day)) {
        return prev.filter(d => d !== day);
      } else {
        return [...prev, day];
      }
    });
  };

  const formatPhoneNumber = (number, country) => {
    const selectedCountry = countryCodeMap[country];
    if (!selectedCountry || !selectedCountry.format) {
      return number;
    }
    return selectedCountry.format(number);
  };



 const getUnformattedNumber = (formattedNumber) => {
    return formattedNumber.replace(/\D/g, '');
  };

  const validatePhoneNumber = (number) => {
    const selectedCountry = countryCodeMap[countryCode];
    if (!selectedCountry) {
      setPhoneError('Invalid country code');
      return false;
    }

    if (!selectedCountry.regex.test(number)) {
      setPhoneError(`Oops - this doesn't look like a phone number from ${selectedCountry.name}!`);
      return false;
    }

    setPhoneError('');
    return true;
  };

  useEffect(() => {
    if (isOpen) {
      fetchUserSettings();
    }
  }, [isOpen]);

  const handlePhoneChange = (e) => {
    const input = e.target;
    const unformattedValue = getUnformattedNumber(input.value);
    setPhoneNumber(unformattedValue);

    const formattedValue = formatPhoneNumber(unformattedValue, countryCode);

    // Calculate the new cursor position
    const cursorPosition = input.selectionStart + (formattedValue.length - input.value.length);

    // Update the input value and cursor position after React has updated the DOM
    setTimeout(() => {
      if (phoneInputRef.current) {
        phoneInputRef.current.value = formattedValue;
        phoneInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);

    validatePhoneNumber(unformattedValue);
  };

 
  const handleSave = async () => {
    if (!await validatePhoneNumber(phoneNumber)) {
      return;
    }

    if (!timeZone) {
      setError('Time zone is not set. Please try refreshing the page.');
      return;
    }

    setSaveStatus('loading');

    try {
      const utcCallTimes = {};
      const updatedDaysToCall = {
        Mon: false, Tue: false, Wed: false, Thu: false, Fri: false, Sat: false, Sun: false
      };

      Object.entries(callTimes).forEach(([localDay, times]) => {
        if (Array.isArray(times) && times.length > 0 && times[0] !== '') {
          times.forEach(time => {
            const utcMoment = moment.tz(`${localDay} ${time}`, 'ddd HH:mm', timeZone).utc();
            const utcDay = utcMoment.format('ddd');
            const utcTimeString = utcMoment.format('HH:mm');
            
            if (!utcCallTimes[utcDay]) {
              utcCallTimes[utcDay] = [];
            }
            utcCallTimes[utcDay].push(utcTimeString);
            updatedDaysToCall[utcDay] = true;
          });
        }
      });

      ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].forEach(day => {
        if (!utcCallTimes[day]) {
          utcCallTimes[day] = [];
        }
      });

      const response = await fetch(ApiURL + '/api/user/settings', {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name,
          phone: phoneNumber,
          callTimes: utcCallTimes,
          daysToCall: updatedDaysToCall,
          timeZone,
          selectedPromptType,
          formattingLevel,
          phone: `${countryCode}${phoneNumber}`,
        }),
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to update settings');
      }

      setSaveStatus('success');
      setOriginalPhoneNumber(phoneNumber);
      setTimeout(() => {
        setSaveStatus('idle');
        onClose();
      }, 1500);
    } catch (error) {
      console.error('Error saving settings:', error);
      setError('Failed to save settings. Please try again.');
      setSaveStatus('idle');
    }
  };

  const handleCancelSubscription = async () => {
    if (!isConfirmingCancel) {
      setIsConfirmingCancel(true);
      return;
    }
  
    try {
      const response = await fetch(ApiURL + '/api/cancel-subscription', {
        method: 'POST',
        credentials: 'include'
      });
  
      if (!response.ok) {
        throw new Error('Failed to cancel subscription');
      }
  
      setSubscriptionStatus('Cancelled');
      setIsConfirmingCancel(false);
      setIsHoveringCancel(false);
      onClose();
    } catch (error) {
      console.error('Error cancelling subscription:', error);
      setError('Failed to cancel subscription. Please try again.');
    }
  };

  const handleDefaultTimeChange = (e) => {
    const newTime = e.target.value;
    setDefaultTime(newTime);
    setIsCustomSchedule(false);
    setCallTimes(prev => {
      const newTimes = { ...prev };
      selectedDays.forEach(day => {
        newTimes[day] = [newTime];
      });
      return newTimes;
    });
  };

  const handleIndividualTimeChange = (day, time) => {
    setCallTimes(prev => ({
      ...prev,
      [day]: time ? [time] : []
    }));
    setIsCustomSchedule(true);
    setDefaultTime('');
  };

  const renderSubscriptionStatus = () => {
    if (isSubscribed) {
      if (activeSubscription === undefined || activeSubscription === true) {
        return (
          <div 
            className="flex items-center cursor-pointer relative"
            onMouseEnter={() => setIsHoveringCancel(true)}
            onMouseLeave={() => {
              if (!isConfirmingCancel) setIsHoveringCancel(false);
            }}
            onClick={handleCancelSubscription}
          >
            {!isHoveringCancel && !isConfirmingCancel && (
              <>
                <CheckCircle className="h-4 w-4 text-green-500 mr-2" />
                <span>Active</span>
              </>
            )}
            {(isHoveringCancel || isConfirmingCancel) && (
              <Button 
                variant="destructive" 
                size="x-sm"
                className="py-1 px-2 text-xs"
              >
                {isConfirmingCancel ? 'Confirm?' : 'Cancel'}
              </Button>
            )}
          </div>
        );
      } else {
        return (
          <div className="flex items-center">
            <AlertTriangle className="h-4 w-4 text-yellow-500 mr-2" />
            <span>Ending</span>
          </div>
        );
      }
    } else {
      return (
        <div className="flex items-center">
          <XCircle className="h-4 w-4 text-red-500 mr-2" />
          <span>Cancelled</span>
        </div>
      );
    }
  };

  const isActivelySubscribed = isSubscribed && (activeSubscription === undefined || activeSubscription === true);

  const disabledClass = isSubscribed ? '' : 'opacity-50 cursor-not-allowed';

  const getPlanDetails = () => {
    const planName = selectedPlan === 'yearly' ? 'Yearly' : 'Monthly';
    const planPrice = selectedPlan === 'yearly' ? '$110/year' : '$11/month';
    return `${planName} - ${planPrice}`;
  };

  const handleExport = async () => {
    setIsExporting(true);
    setExportError('');
    setExportSuccess(false);

    try {
      const response = await fetch(ApiURL + '/api/export-content', {
        method: 'GET',
        credentials: 'include',
      });

      if (!response.ok) {
        throw new Error('Export failed');
      }

      const contentDisposition = response.headers.get('Content-Disposition');
      const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
      const matches = filenameRegex.exec(contentDisposition);
      const filename = matches && matches[1] ? matches[1].replace(/['"]/g, '') : 'reverie_journal_export.zip';

      const blob = await response.blob();
      
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = filename;
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);

      setExportSuccess(true);
    } catch (error) {
      console.error('Error exporting content:', error);
      setExportError('Failed to export content. Please try again.');
    } finally {
      setIsExporting(false);
    }
  };

  const PromptOption = ({ value, title, icon: Icon }) => (
    <Card 
      className={`mb-4 hover:shadow-md transition-shadow cursor-pointer ${
        selectedPromptType === value ? 'ring-2 ring-black dark:ring-white' : ''
      }`}
      onClick={() => setSelectedPromptType(value)}
    >
      <CardContent className="flex items-center justify-center h-full p-4">
        <div className="text-center">
          {Icon && <Icon className="h-6 w-6 mx-auto mb-2" />}
          <h3 className="text-sm font-medium">{title}</h3>
        </div>
      </CardContent>
    </Card>
  );

  const promptOptions = [
    {
      value: "default",
      title: "Default",
      description: "Your prompt will always be 'What do you want to remember about today?'",
      icon: Calendar
    },
    {
      value: "daily",
      title: "Standard",
      description: "You'll receive a new prompt each day, chosen by the Reverie team.",
      icon: Eclipse
    },
    {
      value: "dynamic",
      title: "Dynamic",
      description: "Your prompt will be dynamically generated based on your recent entries.",
      icon: Sparkles
    }
  ];

  const formattingLevels = [
    {
      title: "Basic",
      description: "Reverie will remove ums and uhs and separate out paragraphs, but not much more.",
      icon: (
        <div className="flex justify-center mb-1">
          <Sparkle className="h-4 w-4 text-gray-300" />
        </div>
      )
    },
    {
      title: "Enhanced",
      description: "Reverie will add headings, smooth out your sentences and make the format organized and readable.",
      icon: (
        <div className="flex justify-center mb-1">
          <Sparkles className="h-4 w-4 text-gray-500" />
        </div>
      )
    },
    {
      title: "Stylized",
      description: "Reverie will heavily format, including bolding, italicizing, lists and bullet points and more.",
      icon: (
        <div className="flex justify-center mb-1">
          <WandSparkles className="h-4 w-4 text-yellow-700" />
        </div>
      )
    }
  ];

  const handleFormattingLevelChange = (value) => {
    setFormattingLevel(value[0]);
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[425px] max-h-[80vh] overflow-y-auto dark:bg-black dark:text-white">
        <DialogHeader>
          <DialogTitle>
            <div className='flex items-center'>
              <Settings className='mr-2' /> <p>Settings</p>
            </div>
          </DialogTitle>
        </DialogHeader>
        {error && <div className="text-red-500 mb-4">{error}</div>}
        {isLoading ? (
          <div className="flex justify-center items-center h-[300px] animate-spin">
            <Loader2 className="h-8 w-8 text-gray-500" />
          </div>
        ) : (
          <Tabs defaultValue="general" className="w-full">
            <TabsList className="grid w-full grid-cols-3">
              <TabsTrigger value="general"> <Settings2 className='h-4 w-4 mr-2' />
               General</TabsTrigger>
              <TabsTrigger value="schedule"> <CalendarCheck2 className='h-4 w-4 mr-2' />
                Schedule</TabsTrigger>
              <TabsTrigger value="preferences"> <Cog className='h-4 w-4 mr-2' />
                Options</TabsTrigger>
            </TabsList>
            
            <TabsContent value="general">
              <div className="space-y-4">
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label htmlFor="name" className="text-left">
                    Name
                  </Label>
                  <Input
                    id="name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    className="col-span-3 dark:bg-black"
                  />
                </div>
                <div className="grid grid-cols-4 items-center gap-4">
                  <Label className="text-left">Subscription</Label>
                  <div className="col-span-3 flex items-center justify-between bg-secondary p-2 rounded dark:bg-black">
                    <div className="flex items-center space-x-2">
                      <CreditCard className="h-4 w-4" />
                      <span>{isSubscribed ? getPlanDetails() : 'Not subscribed'}</span>
                    </div>
                    <div className="flex items-center">
                      {renderSubscriptionStatus()}
                    </div>
                  </div>
                </div>
                {!isActivelySubscribed && (
                  <Button onClick={handleResubscribe} className="mt-4 w-full dark:bg-white dark:text-black">
                    Resubscribe
                  </Button>
                )}
                {isSubscribed && activeSubscription === false && (
                  <div className="text-sm text-yellow-500 dark:text-yellow-400 mt-2">
                    Your subscription is ending. You still have access until the end of your current billing period.
                  </div>
                )}
                      <div className={`grid grid-cols-4 items-center gap-4 ${disabledClass}`}>
      <Label htmlFor="phone" className="text-left">
        Phone #
      </Label>
      <div className="col-span-3 flex">
        <Select 
          value={countryCode} 
          onValueChange={(value) => {
            setCountryCode(value);
            setPhoneNumber('');
          }} 
          disabled={!isSubscribed}
        >
          <SelectTrigger className="w-40">
            <SelectValue placeholder="Code" />
          </SelectTrigger>
          <SelectContent>
            {Object.entries(countryCodeMap).map(([code, { flag, name }]) => (
              <SelectItem key={code} value={code}>
                <span className="mr-2">{flag}</span>{code}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
        <Input
          id="phone"
          value={formatPhoneNumber(phoneNumber, countryCode)}
          onChange={handlePhoneChange}
          className={`flex-grow ml-2 dark:bg-black ${phoneError ? 'border-red-500' : ''}`}
          disabled={!isSubscribed}
          ref={phoneInputRef}
        />
      </div>
      {phoneError && (
        <div className="col-span-4 text-red-500 text-sm flex items-center">
          <AlertCircle className="h-4 w-4 mr-2" />
          {phoneError}
        </div>
      )}
    </div>
                <div className={`grid grid-cols-4 items-center gap-4 ${disabledClass}`}>
                  <Label htmlFor="timezone" className="text-left">
                    Time Zone
                  </Label>
                  <Select value={timeZone} onValueChange={setTimeZone} disabled={!isSubscribed}>
                    <SelectTrigger className="col-span-3 dark:bg-black">
                      <SelectValue placeholder="Select time zone" />
                    </SelectTrigger>
                    <SelectContent>
                      {moment.tz.names().map((tz) => (
                        <SelectItem key={tz} value={tz}>
                          {tz}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                </div>
                {timeZone && currentTime && (
                  <div className="text-sm text-gray-500 dark:text-gray-400 mt-1 text-center">
                    It's currently {currentTime} in this time zone
                  </div>
                )}
                        <div className="mt-6">
          <Button
            onClick={handleExport}
            disabled={isExporting || !isSubscribed}
            className="w-full flex items-center justify-center"
          >
            {isExporting ? (
              <>
                <Loader2 className="h-4 w-4 mr-2 animate-spin" />
                Exporting...
              </>
            ) : (
              <>
                <Download className="h-4 w-4 mr-2" />
                Export All Content
              </>
            )}
          </Button>
          {isExporting && (
            <p className="text-sm text-gray-500 mt-2">
              This may take a few minutes...
            </p>
          )}
          {exportError && (
            <p className="text-sm text-red-500 mt-2">{exportError}</p>
          )}
          {exportSuccess && (
            <p className="text-sm text-green-500 mt-2">
              Export successful! Your download should begin shortly.
            </p>
          )}
        </div>
              </div>
            </TabsContent>
            
            <TabsContent value="schedule">
              <div className="space-y-4">
                <div className={`space-y-2 ${disabledClass}`}>
                  <Label className="block text-sm font-medium text-gray-700 dark:text-gray-300">Days to Call</Label>
                  <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                    Select the days you want Reverie to call you:
                  </div>
                  <div className="flex justify-between w-full">
                    {days.map(day => (
                      <DayButton
                        key={day}
                        day={day}
                        selected={callTimes[day] && callTimes[day].length > 0}
                        onClick={() => isSubscribed && handleDayToggle(day)}
                      />
                    ))}
                  </div>
                </div>
                <Tabs defaultValue="default" className={`w-full ${disabledClass}`}>
                  <TabsList className="grid w-full grid-cols-2">
                    <TabsTrigger value="default" disabled={!isSubscribed}>Default</TabsTrigger>
                    <TabsTrigger value="advanced" disabled={!isSubscribed}>Advanced</TabsTrigger>
                  </TabsList>
                  <TabsContent value="default">
                    <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Set a default time for all selected days:
                    </div>
                    <div className="grid grid-cols-4 items-center gap-4">
                      <Label htmlFor="defaultTime" className="text-right">
                        Call Time
                      </Label>
                      <Input
                        id="defaultTime"
                        type="time"
                        value={defaultTime}
                        onChange={handleDefaultTimeChange}
                        className="col-span-3 dark:bg-black"
                        placeholder={isCustomSchedule ? "Custom schedule set" : ""}
                        disabled={!isSubscribed}
                      />
                    </div>
                    {isCustomSchedule && (
                      <div className="text-sm text-gray-500 dark:text-gray-400 mt-2">
                        Custom schedule set. Use advanced tab to view or edit individual times.
                      </div>
                    )}
                  </TabsContent>
                  <TabsContent value="advanced">
                    <div className="text-sm text-gray-500 dark:text-gray-400 mb-2">
                      Set individual times for each day:
                    </div>
                    {days.map(day => (
                      <div key={day} className="grid grid-cols-4 items-center gap-4">
                        <Label htmlFor={`callTime-${day}`} className="text-right">
                          {day}
                        </Label>
                        <Input
                          id={`callTime-${day}`}
                          type="time"
                          value={callTimes[day] && callTimes[day][0] || ''}
                          onChange={(e) => handleIndividualTimeChange(day, e.target.value)}
                          className="col-span-3 dark:bg-black"
                          disabled={!isSubscribed || !selectedDays.includes(day)}
                        />
                      </div>
                    ))}
                  </TabsContent>
                </Tabs>
              </div>
            </TabsContent>
            
            <TabsContent value="preferences">
    <div className="space-y-4">
      <Label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mt-5">Prompt</Label>
      <div className="grid grid-cols-3 gap-4">
        {promptOptions.map((option) => (
          <PromptOption key={option.value} {...option} />
        ))}
      </div>
      {selectedPromptType && (
        <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-md">
          <p className="text-sm text-gray-600 dark:text-gray-400">
            {promptOptions.find(option => option.value === selectedPromptType)?.description}
          </p>
        </div>
      )}

      <div className="border-t border-gray-200 dark:border-gray-700 mt-4" />

      <div className="mt-8">
        <Label className="block text-sm font-medium text-gray-700 dark:text-gray-300 mb-2">Formatting</Label>
        <div className="mb-2 flex justify-between">
  {formattingLevels.map((level, index) => (
    <div key={index} className="text-center">
      {level.icon}
      <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
        {level.title}
      </span>
    </div>
  ))}
</div>
        <Slider
          value={[formattingLevel]}
          onValueChange={handleFormattingLevelChange}
          max={2}
          step={1}
          className="mb-4"
        />
<div className="text-sm text-gray-600 dark:text-gray-400">
  {formattingLevels[formattingLevel].description}
</div>
      </div>
    </div>
  </TabsContent>
          </Tabs>
        )}
        <DialogFooter>
          <Button 
            onClick={handleSave} 
            disabled={!isSubscribed || saveStatus === 'loading' || saveStatus === 'success' || isValidatingPhone}
            className={`w-32 ${saveStatus === 'success' ? 'bg-green-800 hover:bg-green-700' : ''}`}
          >
            {saveStatus === 'idle' && <span className="flex items-center">
              <Save className="h-4 w-4 mr-2" /> Save
            </span>}
            {saveStatus === 'loading' && <Loader2 className="h-4 w-4 animate-spin" />}
            {saveStatus === 'success' && (
              <span className="flex items-center">
                <CheckCircle className="h-4 w-4 mr-2" />
                Saved!
              </span>
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default SettingsModal;