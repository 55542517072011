import React, { useState, useEffect, useRef } from 'react';
import { Button } from "./ui/button"
import { Input } from "./ui/input"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./ui/tabs"
import { Phone, User, Clock, Calendar, Check, Lock, Sparkles, FileDown, Loader2, ArrowRight, CalendarCheck2, WandSparkles, Sparkle, Percent, Mail, Undo2 } from 'lucide-react';
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "./ui/select"
import { motion, AnimatePresence } from 'framer-motion';
import moment from 'moment-timezone';
import { loadStripe } from '@stripe/stripe-js';
import { Alert, AlertDescription } from "./ui/alert"
import { Card, CardContent } from "./ui/card"
import { Slider } from "./ui/slider"


const AuthComponent = ({ onLogin }) => {
  const [activeTab, setActiveTab] = useState('login');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isRegistering, setIsRegistering] = useState(false);
  const [showConfetti, setShowConfetti] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [registrationStep, setRegistrationStep] = useState(1);
  const [errorMessage, setErrorMessage] = useState('');
  const [phoneError, setPhoneError] = useState('');
  const [isValidatingPhone, setIsValidatingPhone] = useState(false);
  const [userId, setUserId] = useState(null);
  const [showSuccessPage, setShowSuccessPage] = useState(false);

  const [nameError, setNameError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [isValidatingEmail, setIsValidatingEmail] = useState(false);
  const [daysToCall, setDaysToCall] = useState({
    Mon: false, Tue: false, Wed: false, Thu: false, Fri: false, Sat: false, Sun: false
  });

  const [showForgotPassword, setShowForgotPassword] = useState(false);
  const [forgotEmail, setForgotEmail] = useState('');
  const [forgotPasswordSuccess, setForgotPasswordSuccess] = useState(false);


  const [defaultCallTime, setDefaultCallTime] = useState('');
  const [advancedCallTimes, setAdvancedCallTimes] = useState({
    Mon: '', Tue: '', Wed: '', Thu: '', Fri: '', Sat: '', Sun: ''
  });
  const [activeTimeTab, setActiveTimeTab] = useState('default');
  const [timeZone, setTimeZone] = useState('');
  const [timeZoneError, setTimeZoneError] = useState('');
  const stripePromise = loadStripe('pk_live_51LZK81EVmyPNhExzHXm1BF3uC8BO6iTdg6R3hrrcjo3XEnq6fral763hSpQppYgCnRzNZO6zeCVHdq2aJ835k37e00Vhl33gOG');
  

  const [showResetPassword, setShowResetPassword] = useState(false);
  const [resetToken, setResetToken] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [resetPasswordSuccess, setResetPasswordSuccess] = useState(false);

  const [countryCode, setCountryCode] = useState('+1');
  const [phoneNumber, setPhoneNumber] = useState('');
  const phoneInputRef = useRef(null);

  const [selectedPromptType, setSelectedPromptType] = useState('default');
  const [formattingLevel, setFormattingLevel] = useState(1);

  const countryCodeMap = {
    '+1': { 
      flag: '🇨🇦 🇺🇸',
      name: 'Canada/US', 
      regex: /^[2-9]\d{9}$/, 
      format: (num) => {
        if (num.length === 10) {
          return `(${num.slice(0, 3)}) ${num.slice(3, 6)}-${num.slice(6)}`;
        }
        return num; // Return unformatted for any other length
      }
    },
    '+44': { 
      flag: '🇬🇧', 
      name: 'UK', 
      regex: /^[1-9]\d{9,10}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 4)} ${num.slice(4, 7)} ${num.slice(7)}`.trim() : '' 
    },
    '+49': { 
      flag: '🇩🇪', 
      name: 'Germany', 
      regex: /^[1-9]\d{7,14}$/, 
      format: (num) => num 
    },
    '+61': { 
      flag: '🇦🇺', 
      name: 'Australia', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 4)} ${num.slice(4)}`.trim() : '' 
    },
    '+82': { 
      flag: '🇰🇷', 
      name: 'South Korea', 
      regex: /^[1-9]\d{7,9}$/, 
      format: (num) => num 
    },
    '+31': { 
      flag: '🇳🇱', 
      name: 'Netherlands', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`.trim() : '' 
    },
    '+33': { 
      flag: '🇫🇷', 
      name: 'France', 
      regex: /^[1-9]\d{8,9}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 5)} ${num.slice(5, 7)} ${num.slice(7)}`.trim() : '' 
    },
    '+34': { 
      flag: '🇪🇸', 
      name: 'Spain', 
      regex: /^[6-9]\d{8}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 3)} ${num.slice(3, 6)} ${num.slice(6)}`.trim() : '' 
    },
    '+91': { 
      flag: '🇮🇳', 
      name: 'India', 
      regex: /^[1-9]\d{9,10}$/, 
      format: (num) => num.length > 0 ? `${num.slice(0, 5)} ${num.slice(5)}`.trim() : '' 
    },
  };

  

 // const ApiURL = 'http://localhost:5000';
  const ApiURL = 'https://api.callreverie.com';

 const handleEmailChange = (e) => {
  setEmail(e.target.value.toLowerCase());
};

const handleForgotEmailChange = (e) => {
  setForgotEmail(e.target.value.toLowerCase());
};



  useEffect(() => {
    // Check if there's a reset token in the URL
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get('token');
    if (token) {
      setResetToken(token);
      setShowResetPassword(true);
      setActiveTab('login'); // Ensure we're on the login tab
    }
  }, []);

  const handleResetPassword = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setErrorMessage("Passwords don't match");
      return;
    }
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(`${ApiURL}/api/reset-password/${resetToken}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ password: newPassword }),
      });
      if (response.ok) {
        setResetPasswordSuccess(true);
        setTimeout(() => {
          setShowResetPassword(false);
          resetLogin();
        }, 3000);
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'An error occurred');
      }
    } catch (error) {
      setErrorMessage('Network error. Please try again.');
    }
    setIsLoading(false);
  };

  const renderResetPassword = () => (
    <motion.div
      key="reset-password"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: -50 }}
      transition={{ duration: 0.3 }}
    >
      <h2 className="text-xl font-semibold text-center mb-4">Reset Your Password</h2>
      {resetPasswordSuccess ? (
        <motion.div
          initial={{ scale: 0 }}
          animate={{ scale: 1 }}
          transition={{ type: "spring", stiffness: 260, damping: 20 }}
        >
          <Alert className="bg-green-100 border-green-400 text-green-700">
            <Check className="h-4 w-4" />
            <AlertDescription>
              Your password has been reset successfully. You can now log in with your new password.
            </AlertDescription>
          </Alert>
        </motion.div>
      ) : (
        <form onSubmit={handleResetPassword}>
          <div className="flex items-center space-x-2 mb-4">
            <Lock className="text-gray-400" size={20} />
            <Input
              type="password"
              placeholder="New Password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
          </div>
          <div className="flex items-center space-x-2 mb-4">
            <Lock className="text-gray-400" size={20} />
            <Input
              type="password"
              placeholder="Confirm New Password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
          </div>
          <Button type="submit" className="w-full" disabled={isLoading}>
            {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
            Reset Password
          </Button>
        </form>
      )}
    </motion.div>
  );


  useEffect(() => {
    // Check if user is already logged in
    checkAuthStatus();
  }, []);


  const handlePlanSelect = (plan) => {
    setSelectedPlan(plan);
  };

  useEffect(() => {
    // Try to guess the user's time zone
    const guessedTimeZone = moment.tz.guess();
    setTimeZone(guessedTimeZone);
  }, []);

  const validateName = () => {
    if (name.trim().length < 2) {
      setNameError('Name must be at least 2 characters long');
      return false;
    }
    setNameError('');
    return true;
  };
// Update the email validation function
  const validateEmail = async () => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email.toLowerCase())) {
      setEmailError('Please enter a valid email address');
      return false;
    }
    
    setIsValidatingEmail(true);
    try {
      const response = await fetch(ApiURL + `/api/validate-email?email=${encodeURIComponent(email.toLowerCase())}`);
      const data = await response.json();
      if (!data.isAvailable) {
        setEmailError(data.message);
        return false;
      }
    } catch (error) {
      console.error('Error validating email:', error);
      setEmailError('Error validating email');
      return false;
    } finally {
      setIsValidatingEmail(false);
    }
    
    setEmailError('');
    return true;
  };

  const validatePassword = () => {
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters long');
      return false;
    }
    if (!/(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]/.test(password)) {
      setPasswordError('Password must include uppercase, lowercase, number, and special character');
      return false;
    }
    setPasswordError('');
    return true;
  };


  const validatePhoneNumber = async () => {
    const selectedCountry = countryCodeMap[countryCode];
    if (!selectedCountry) {
      setPhoneError('Invalid country code');
      return false;
    }

    if (!selectedCountry.regex.test(phoneNumber)) {
      setPhoneError(`Oops - this doesn't look like a phone number from ${selectedCountry.name}!`);
      return false;
    }

    // Prepare the full phone number for validation
    const fullPhoneNumber = `${countryCode}${phoneNumber}`;
    const phoneWithoutPlus = fullPhoneNumber.replace('+', '');

    setIsValidatingPhone(true);
    try {
      const response = await fetch(`${ApiURL}/api/validate-phone?phone=${encodeURIComponent(fullPhoneNumber)}`);
      const data = await response.json();
      if (!data.isAvailable) {
        setPhoneError(data.message);
        return false;
      }

      // If the first check passed, also check without the '+' sign
      if (fullPhoneNumber !== phoneWithoutPlus) {
        const responseWithoutPlus = await fetch(`${ApiURL}/api/validate-phone?phone=${encodeURIComponent(phoneWithoutPlus)}`);
        const dataWithoutPlus = await responseWithoutPlus.json();
        if (!dataWithoutPlus.isAvailable) {
          setPhoneError(dataWithoutPlus.message);
          return false;
        }
      }
    } catch (error) {
      console.error('Error validating phone:', error);
      setPhoneError('Error validating phone number');
      return false;
    } finally {
      setIsValidatingPhone(false);
    }

    setPhoneError('');
    return true;
  };



  const formatPhoneNumber = (number, country) => {
    const format = countryCodeMap[country].format;
    return format(number);
  };

  const getUnformattedNumber = (formattedNumber) => {
    return formattedNumber.replace(/\D/g, '');
  };

  const handlePhoneChange = (e) => {
    const input = e.target;
    const selectionStart = input.selectionStart;
    const previousFormattedValue = input.value;
    const previousUnformattedValue = getUnformattedNumber(previousFormattedValue);
    
    const unformattedValue = getUnformattedNumber(input.value);
    const formattedValue = formatPhoneNumber(unformattedValue, countryCode);
    
    setPhoneNumber(unformattedValue);

    // Calculate the new cursor position
    const cursorPosition = selectionStart + (formattedValue.length - previousFormattedValue.length);

    // Update the input value and cursor position after React has updated the DOM
    setTimeout(() => {
      if (phoneInputRef.current) {
        phoneInputRef.current.value = formattedValue;
        phoneInputRef.current.setSelectionRange(cursorPosition, cursorPosition);
      }
    }, 0);
  };



  const convertToUTC = (time, zone) => {
    if (!time || !zone) return '';
    const localTime = moment.tz(`1970-01-01 ${time}`, zone);
    return localTime.utc().format('HH:mm');
  };

  const handleDefaultTimeChange = (e) => {
    const localTime = e.target.value;
    setDefaultCallTime(localTime);
    const updatedCallTimes = {};
    Object.keys(daysToCall).forEach(day => {
      if (daysToCall[day]) {
        updatedCallTimes[day] = [localTime];
      } else {
        updatedCallTimes[day] = [];
      }
    });
    setCallTimes(updatedCallTimes);
    setScheduleError('');
  };

  const handleAdvancedTimeChange = (day, localTime) => {
    setCallTimes(prev => ({
      ...prev,
      [day]: localTime ? [localTime] : []
    }));
    setScheduleError('');
  };

  const validateTimeZone = () => {
    if (!timeZone) {
      setTimeZoneError('Please select a time zone');
      return false;
    }
    setTimeZoneError('');
    return true;
  };


  const validateCallSchedule = () => {
    if (!validateTimeZone()) return false;

    const selectedDays = Object.values(daysToCall).filter(Boolean).length;
    if (selectedDays === 0) {
      setScheduleError('Please select at least one day for calls');
      return false;
    }

    const hasTimeForSelectedDay = Object.entries(daysToCall).some(
      ([day, isSelected]) => isSelected && callTimes[day].length > 0
    );
    if (!hasTimeForSelectedDay) {
      setScheduleError('Please set a time for at least one selected day');
      return false;
    }

    return true;
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(ApiURL + '/api/protected', {
        credentials: 'include' // This is important for sending cookies
      });
      if (response.ok) {
        onLogin(); // User is authenticated
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
    }
  };

  useEffect(() => {
    if (showConfetti) {
      const timer = setTimeout(() => {
        setShowConfetti(false);
      }, 5000);
      return () => clearTimeout(timer);
    }
  }, [showConfetti]);
  
  // Registration fields
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [callTimes, setCallTimes] = useState({
    Mon: [], Tue: [], Wed: [], Thu: [], Fri: [], Sat: [], Sun: []
  });

  


  const [selectedPlan, setSelectedPlan] = useState('monthly');
  const [scheduleError, setScheduleError] = useState('');

  // Starry sky effect
  const canvasRef = useRef(null);
  const [stars, setStars] = useState([]);
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

  useEffect(() => {
    const handleResize = () => {
      setDimensions({
        width: window.innerWidth,
        height: window.innerHeight
      });
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (dimensions.width && dimensions.height) {
      setStars(createStars(dimensions.width, dimensions.height, 100));
    }
  }, [dimensions]);

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext('2d');
    let animationFrameId;

    const render = () => {
      drawStars(ctx, stars, dimensions);
      updateStars(stars);
      animationFrameId = window.requestAnimationFrame(render);
    };
    render();

    return () => {
      window.cancelAnimationFrame(animationFrameId);
    };
  }, [stars, dimensions]);

  const createStars = (width, height, count) => {
    return Array.from({ length: count }, () => ({
      x: Math.random() * width,
      y: Math.random() * height,
      radius: Math.random() * 1.5,
      opacity: Math.random()
    }));
  };

  const drawStars = (ctx, stars, { width, height }) => {
    ctx.clearRect(0, 0, width, height);
    stars.forEach(star => {
      ctx.beginPath();
      ctx.arc(star.x, star.y, star.radius, 0, Math.PI * 2);
      ctx.fillStyle = `rgba(255, 255, 255, ${star.opacity})`;
      ctx.fill();
    });
  };


  const updateStars = (stars) => {
    stars.forEach(star => {
      star.opacity = Math.sin(Date.now() / 1000 * star.radius) * 0.5 + 0.5;
    });
  };

  const handleEmailSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    setShowPassword(true);
    setIsLoading(false);
  };

// Update the login function
  const handleLogin = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(ApiURL + '/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: email.toLowerCase(), password }),
        credentials: 'include'
      });
      if (response.ok) {
        setShowConfetti(true);
        onLogin();
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Invalid credentials');
      }
    } catch (error) {
      setErrorMessage('Network error. Please try again.');
    }
    setIsLoading(false);
  };


  const handleRegisterNextStep = async () => {
    switch (registrationStep) {
      case 1:
        if (!validateName()) return;
        break;
      case 2:
        if (!(await validateEmail()) || !validatePassword()) return;
        break;
      case 3:
        if (!(await validatePhoneNumber())) return;
        break;
      case 4:
        if (!validateCallSchedule()) return;
        break;
      case 5:
        // Prompt selection doesn't need validation
        break;
      case 6:
        // Formatting level doesn't need validation
        break;
    }
    setRegistrationStep(prev => prev + 1);
  };


  const handleRegisterPrevStep = () => {
    setRegistrationStep(prev => prev - 1);
  };

  const handleRegister = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
  
    // Convert local times to UTC and determine daysToCall
    const callTimesUTC = {};
    const daysToCallUTC = {
      Mon: false, Tue: false, Wed: false, Thu: false, Fri: false, Sat: false, Sun: false
    };
  
    Object.entries(callTimes).forEach(([localDay, times]) => {
      if (times.length > 0) {
        times.forEach(time => {
          const utcDateTime = moment.tz(`${localDay} ${time}`, 'ddd HH:mm', timeZone).utc();
          const utcDay = utcDateTime.format('ddd');
          const utcTime = utcDateTime.format('HH:mm');
  
          if (!callTimesUTC[utcDay]) {
            callTimesUTC[utcDay] = [];
          }
          callTimesUTC[utcDay].push(utcTime);
          daysToCallUTC[utcDay] = true;
        });
      }
    });
  
    try {
      const response = await fetch(ApiURL + '/api/register', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ 
          name, 
          email: email.toLowerCase(),  
          password, 
          phone: `${countryCode}${phoneNumber}`,
          callTimes: callTimesUTC,
          daysToCall: daysToCallUTC,
          timeZone,
          selectedPlan,
          selectedPromptType,
          formattingLevel
        }),
        credentials: 'include'
      });
  
      if (response.ok) {
        const data = await response.json();
        setUserId(data.userId);
        
        if (window.fbq) {
          window.fbq('track', 'CompleteRegistration', {
            content_name: selectedPlan + ' trial',
            status: 'success'
          });
        }

        // Redirect to Stripe checkout
        const stripe = await stripePromise;
        const checkoutResponse = await fetch(ApiURL + '/api/create-checkout-session', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            plan: selectedPlan,
            userId: data.userId,
          }),
        });
        const session = await checkoutResponse.json();
        const result = await stripe.redirectToCheckout({
          sessionId: session.id,
        });
        if (result.error) {
          setErrorMessage(result.error.message);
        }
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'Registration failed');
      }
    } catch (error) {
      console.error('Registration error:', error);
      setErrorMessage('Network error. Please try again.');
    }
    setIsLoading(false);
  };

  const PromptOption = ({ value, title, icon: Icon }) => (
    <Card 
      className={`mb-4 hover:shadow-md transition-shadow cursor-pointer ${
        selectedPromptType === value ? 'ring-2 ring-black' : ''
      }`}
      onClick={() => setSelectedPromptType(value)}
    >
      <CardContent className="flex items-center justify-center h-full p-4">
        <div className="text-center">
          {Icon && <Icon className="h-6 w-6 mx-auto mb-2" />}
          <h3 className="text-sm font-medium">{title}</h3>
        </div>
      </CardContent>
    </Card>
  );

  const promptOptions = [
    {
      value: "default",
      title: "Default",
      description: "Reverie will always ask 'What do you want to remember about today?'",
      icon: Calendar
    },
    {
      value: "daily",
      title: "Standard",
      description: "You'll receive a new prompt each day, curated by the Reverie team.",
      icon: CalendarCheck2
    },
    {
      value: "dynamic",
      title: "Dynamic",
      description: "Your prompt will be dynamically generated based on your recent entries.",
      icon: Sparkles
    }
  ];

  const formattingLevels = [
    {
      title: "Basic",
      description: "Reverie will remove 'umms' and 'uhhs' and separate out paragraphs, but not much more.",
      icon: (
        <div className="flex justify-center mb-1">
          <Sparkle className="h-4 w-4 text-gray-300" />
        </div>
      )
    },
    {
      title: "Enhanced",
      description: "Reverie will add headings, smooth out your sentences and make the format organized and readable.",
      icon: (
        <div className="flex justify-center mb-1">
          <Sparkles className="h-4 w-4 text-gray-500" />
        </div>
      )
    },
    {
      title: "Stylized",
      description: "Reverie will heavily format, including bolding, italicizing, underlining, making lists and more.",
      icon: (
        <div className="flex justify-center mb-1">
          <WandSparkles className="h-4 w-4 text-yellow-700" />
        </div>
      )
    }
  ];

  const handleFormattingLevelChange = (value) => {
    setFormattingLevel(value[0]);
  };

  const toggleDay = (day) => {
    setDaysToCall(prev => {
      const updatedDays = { ...prev, [day]: !prev[day] };
      // Update callTimes based on the new daysToCall
      setCallTimes(prevTimes => ({
        ...prevTimes,
        [day]: updatedDays[day] ? (prevTimes[day].length ? prevTimes[day] : [defaultCallTime]) : []
      }));
      return updatedDays;
    });
    setScheduleError('');
  };

  const PricingOption = ({ title, price, isSelected, onSelect, term, isYearly }) => {
  const handleClick = () => {
    onSelect(isYearly ? 'yearly' : 'monthly');
  };
  
    return (
      <div 
        className={`border rounded-lg m-1 p-4 cursor-pointer ${isSelected ? 'border-blue-500 bg-blue-50 dark:border dark:border-white dark:bg-gray-700' : 'border-gray-200 dark:border-none'}`}
        onClick={handleClick}
      >
      <h3 className="text-lg font-semibold dark:text-white">{title}</h3>
      <p className="text-2xl font-bold my-2 dark:text-white">{price}<span className='text-sm'> / {term}</span></p>
      <ul className="space-y-2">
        {isYearly && (
          <li className="flex items-center text-sm dark:text-white"><Percent size={16} className="mr-2 text-green-500" /> 16% off</li>
        )}
        <li className="flex items-center text-sm dark:text-white"><Check size={16} className="mr-2 text-green-500" /> 5-day trial</li>
        <li className="flex items-center text-sm dark:text-white"><Phone size={16} className="mr-2 text-green-500" /> Daily calls</li>
        <li className="flex items-center text-sm dark:text-white"><Sparkles size={16} className="mr-2 text-green-500" /> Formatting & Tags</li>
        <li className="flex items-center text-sm dark:text-white"><FileDown size={16} className="mr-2 text-green-500" /> Easy export</li>
        <li className="flex items-center text-sm dark:text-white"><Lock size={16} className="mr-2 text-green-500" /> Bank-level security</li>
      </ul>
      </div>
  );
};

 // reset login form fields and view
  const resetLogin = () => {
    setEmail('');
    setPassword('');
    setShowPassword(false);
    setErrorMessage('');
    setShowForgotPassword(false); // Ensure we're not showing the forgot password form
    setResetPasswordSuccess(false); // Reset the success state
  };


  const handleForgotPassword = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    setErrorMessage('');
    try {
      const response = await fetch(ApiURL + '/api/forgot-password', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email: forgotEmail }),
      });
      if (response.ok) {
        setForgotPasswordSuccess(true);
      } else {
        const data = await response.json();
        setErrorMessage(data.message || 'An error occurred');
      }
    } catch (error) {
      setErrorMessage('Network error. Please try again.');
    }
    setIsLoading(false);
  };



  const renderRegistrationStep = () => {
    switch (registrationStep) {
      case 1:
        return (
          <motion.div
            key="step1"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">What should Reverie call you?</h2>
            <div className="flex items-center space-x-2 mb-4">
              <User className="text-gray-400" size={20} />
              <Input
                type="text"
                placeholder="Name"
                value={name}
                onChange={(e) => { setName(e.target.value); setNameError(''); }}
                required
                className="dark:text-white"
              />
            </div>
            {nameError && <p className="text-red-500 text-sm mb-4">{nameError}</p>}
            <Button onClick={handleRegisterNextStep} className="w-full">Next</Button>
          </motion.div>
        );
      case 2:
        return (
          <motion.div
            key="step2"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">Create your account</h2>
            <div className="flex items-center space-x-2 mb-4 dark:text-white">
              <Mail className="text-gray-400" size={20} />
              <Input
                type="email"
                placeholder="Email"
                value={email}
                onChange={(e) => { setEmail(e.target.value); setEmailError(''); }}
                required
                className="dark:text-white"
              />
            </div>
            {emailError && <p className="text-red-500 text-sm mb-4">{emailError}</p>}
            <div className="flex items-center space-x-2 mb-4">
              <Lock className="text-gray-400" size={20} />
              <Input
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => { setPassword(e.target.value); setPasswordError(''); }}
                required
                className="dark:text-white"
              />
            </div>
            {passwordError && <p className="text-red-500 text-sm mb-4">{passwordError}</p>}
            <div className="flex justify-between">
              <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
              <Button 
                onClick={handleRegisterNextStep}
                disabled={isValidatingEmail}
              >
                {isValidatingEmail ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                Next
              </Button>
            </div>
          </motion.div>
        );
        case 3:
        return (
          <motion.div
            key="step3"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">What number should Reverie call?</h2>
            <div className="flex items-center space-x-2 mb-2">
              <Phone className="text-gray-400" size={20} />
              <Select value={countryCode} onValueChange={(value) => {
                setCountryCode(value);
                setPhoneNumber(''); // Reset phone number when country changes
              }}>
                <SelectTrigger className="w-36">
                  <SelectValue placeholder="Code" />
                </SelectTrigger>
                <SelectContent>
                  {Object.entries(countryCodeMap).map(([code, { flag, name }]) => (
                    <SelectItem key={code} value={code}>
                      <span className="mr-2">{flag}</span>{code}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
              <Input
                type="tel"
                placeholder="Phone number"
                value={formatPhoneNumber(phoneNumber, countryCode)}
                onChange={handlePhoneChange}
                ref={phoneInputRef}
                required
                className="dark:text-white flex-grow"
              />
            </div>
            <div className="flex items-center justify-center space-x-2 mb-2 text-sm text-gray-500">
              <span>Reverie will call and text you at this number.</span>
            </div>
            {phoneError && <p className="text-red-500 text-sm mb-4">{phoneError}</p>}
            <div className="flex justify-between mt-4">
              <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
              <Button 
                onClick={handleRegisterNextStep}
                disabled={isValidatingPhone || !phoneNumber}
              >
                {isValidatingPhone ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                Next
              </Button>
            </div>
          </motion.div>
        );
        case 4:
        return (
          <motion.div
            key="step4"
            initial={{ opacity: 0, x: 50 }}
            animate={{ opacity: 1, x: 0 }}
            exit={{ opacity: 0, x: -50 }}
            transition={{ duration: 0.3 }}
          >
            <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">When should Reverie call you?</h2>
            <div className="space-y-4 mb-4">
              <p className="text-sm text-gray-500">Confirm your timezone:</p>
              <div className="flex items-center space-x-2 mb-4">
                <Clock className="text-gray-400" size={20} />
                <Select value={timeZone} onValueChange={setTimeZone}>
                  <SelectTrigger className="w-full dark:text-white">
                    <SelectValue placeholder="Select your time zone" />
                  </SelectTrigger>
                  <SelectContent>
                    {moment.tz.names().map((tz) => (
                      <SelectItem key={tz} value={tz} className='dark:text-white'>
                        {tz}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              {timeZoneError && <p className="text-red-500 text-sm">{timeZoneError}</p>}
              <label className="flex items-center space-x-2">
                <Calendar className="text-gray-400" size={20} />
                <span className='dark:text-white'>Days to Call</span>
              </label>
              <p className="text-sm text-gray-500">Choose the days you want to receive a call:</p>
              <div className="flex justify-between">
                {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                  <button
                    key={day}
                    type="button"
                    onClick={() => toggleDay(day)}
                    className={`w-8 h-8 rounded-full ${daysToCall[day] ? 'bg-gray-700 text-white dark:text-gray-700 dark:bg-gray-200' : 'bg-gray-200 dark:bg-gray-800 text-white'}`}
                  >
                    {day[0]}
                  </button>
                ))}
              </div>
              <Tabs value={activeTimeTab} onValueChange={setActiveTimeTab}>
                <TabsList className="grid w-full grid-cols-2">
                  <TabsTrigger value="default">Default</TabsTrigger>
                  <TabsTrigger value="advanced">Advanced</TabsTrigger>
                </TabsList>
                <p className="text-sm text-gray-500 mt-4 dark:text-white">Choose what time you want to be called at:</p>
                <TabsContent value="default">
                  <div className="flex items-center space-x-2">
                    <Clock className="text-gray-400" size={20} />
                    <Input
                      type="time"
                      value={defaultCallTime}
                      onChange={handleDefaultTimeChange}
                      required
                      className="dark:text-white"
                    />
                  </div>
                </TabsContent>
                <TabsContent value="advanced">
                  {['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'].map((day) => (
                    <div key={day} className="flex items-center space-x-2 mt-2 dark:text-white">
                      <label className="w-10">{day}</label>
                      <Input
                        type="time"
                        value={advancedCallTimes[day]}
                        onChange={(e) => handleAdvancedTimeChange(day, e.target.value)}
                        disabled={!daysToCall[day]}
                        className="dark:text-white"
                      />
                    </div>
                  ))}
                </TabsContent>
              </Tabs>
            </div>
            {scheduleError && <p className="text-red-500 text-sm mb-4">{scheduleError}</p>}
            <div className="flex justify-between">
              <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
              <Button onClick={handleRegisterNextStep}>Next</Button>
            </div>
          </motion.div>
          );
          case 5:
            return (
              <motion.div
                key="step5"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">What kind of prompts do you want?</h2>
                <div className="space-y-4 mb-4">
                  <div className="grid grid-cols-3 gap-4">
                    {promptOptions.map((option) => (
                      <PromptOption key={option.value} {...option} />
                    ))}
                  </div>
                  {selectedPromptType && (
                    <div className="mt-4 p-4 bg-gray-100 dark:bg-gray-800 rounded-md">
                      <p className="text-sm text-gray-600 dark:text-gray-400">
                        {promptOptions.find(option => option.value === selectedPromptType)?.description}
                      </p>
                    </div>
                  )}
                </div>
                <div className="flex justify-between mt-4">
                  <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
                  <Button onClick={handleRegisterNextStep}>Next</Button>
                </div>
              </motion.div>
            );
          case 6:
            return (
              <motion.div
                key="step6"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">Choose Your Formatting Level</h2>
                <div className="space-y-4 mb-4">
                  <div className="mb-2 flex justify-between">
                    {formattingLevels.map((level, index) => (
                      <div key={index} className="text-center">
                        {level.icon}
                        <span className="text-xs font-medium text-gray-500 dark:text-gray-400">
                          {level.title}
                        </span>
                      </div>
                    ))}
                  </div>
                  <Slider
                    value={[formattingLevel]}
                    onValueChange={handleFormattingLevelChange}
                    max={2}
                    step={1}
                    className="mb-4"
                  />
                  <div className="text-sm text-gray-600 dark:text-gray-400">
                    {formattingLevels[formattingLevel].description}
                  </div>
                </div>
                <div className="flex justify-between mt-4">
                  <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
                  <Button onClick={handleRegisterNextStep}>Next</Button>
                </div>
              </motion.div>
            );
          case 7:
            return (
              <motion.div
                key="step7"
                initial={{ opacity: 0, x: 50 }}
                animate={{ opacity: 1, x: 0 }}
                exit={{ opacity: 0, x: -50 }}
                transition={{ duration: 0.3 }}
              >
                <div className="space-y-4 mb-4">
                  <h3 className="text-lg font-semibold dark:text-white">Select a Plan</h3>
                  <div className='flex justify-between'>
                    <PricingOption
                      title="By Month"
                      isYearly={false}
                      price="$11"
                      term="month"
                      isSelected={selectedPlan === 'monthly'}
                      onSelect={() => setSelectedPlan('monthly')}
                    />
                    <PricingOption
                      title="By Year"
                      isYearly={true}
                      price="$111"
                      term="year"
                      isSelected={selectedPlan === 'yearly'}
                      onSelect={() => setSelectedPlan('yearly')}
                    />
                  </div>
                </div>
                <div className="flex justify-between">
                  <Button onClick={handleRegisterPrevStep}><Undo2 className="h-4 w-4" /></Button>
                  <Button onClick={handleRegister} disabled={isLoading}>
                    {isLoading ? (
                      <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                    ) : null}
                    Start Free Trial
                  </Button>
                </div>
              </motion.div>
            );
          default:
            return null;
        }
      };
  
  return (
    <div>
    <div className="flex flex-col items-center justify-center min-h-screen bg-black dark:bg-gray-900 relative">
      <canvas 
        ref={canvasRef}
        className="absolute top-0 left-0 w-full h-full"
        width={dimensions.width}
        height={dimensions.height}
      />
      <h1 className="text-6xl font-bold text-center mb-8 text-white reverie-font relative z-10 mt-10">Reverie</h1>
      <div className="sm:w-3/4 max-w-md p-8 space-y-3 rounded-xl bg-white dark:bg-gray-800 shadow-lg relative z-10 mb-10">
        <Tabs value={activeTab} onValueChange={setActiveTab}>
        <TabsList className="grid w-full grid-cols-2 mb-6">
          <TabsTrigger value="login">Login</TabsTrigger>
          <TabsTrigger value="register">Register</TabsTrigger>
        </TabsList>
        <TabsContent value="login">
              <AnimatePresence mode="wait">
              {showResetPassword ? (
                  renderResetPassword()
                ) : showForgotPassword ? (
                  <motion.div
                    key="forgot-password"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.3 }}
                  >
                    <h2 className="text-xl font-semibold text-center mb-4 dark:text-white">Reset Password</h2>
                    {forgotPasswordSuccess ? (
                      <motion.div
                        initial={{ scale: 0 }}
                        animate={{ scale: 1 }}
                        transition={{ type: "spring", stiffness: 260, damping: 20 }}
                      >
                        <Alert className="bg-green-100 border-green-400 text-green-700">
                          <Check className="h-4 w-4" />
                          <AlertDescription>
                            Email sent!
                          </AlertDescription>
                        </Alert>
                      </motion.div>
                    ) : (
                      <form onSubmit={handleForgotPassword}>
                        <div className="flex items-center space-x-2 mb-4 dark:text-white">
                          <Mail className="text-gray-400" size={20} />
                          <Input
                            type="email"
                            placeholder="Email"
                            value={forgotEmail}
                            onChange={(e) => setForgotEmail(e.target.value)}
                            required
                          />
                        </div>
                        <Button type="submit" className="w-full" disabled={isLoading}>
                          {isLoading ? <Loader2 className="mr-2 h-4 w-4 animate-spin" /> : null}
                          Send Reset Link
                        </Button>
                      </form>
                    )}
                    <Button
                      variant="link"
                      className="w-full mt-4"
                      onClick={() => setShowForgotPassword(false)}
                    >
                      Back to Login
                    </Button>
                  </motion.div>
                ) : (
                  <motion.div
                    key="login"
                    initial={{ opacity: 0, y: 50 }}
                    animate={{ opacity: 1, y: 0 }}
                    exit={{ opacity: 0, y: -50 }}
                    transition={{ duration: 0.3 }}
                  >
                    <form onSubmit={showPassword ? handleLogin : handleEmailSubmit} className="space-y-6">
                      <AnimatePresence mode="wait">
                        {!showPassword && (
                          <motion.div
                            key="email"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.3 }}
                          >
                            <div className="flex items-center space-x-2 dark:text-white">
                              <Mail className="text-gray-400" size={20} />
                              <Input
                                type="email"
                                placeholder="Email"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                required
                              />
                            </div>
                          </motion.div>
                        )}
                        {showPassword && (
                          <motion.div
                            key="password"
                            initial={{ opacity: 0, y: 50 }}
                            animate={{ opacity: 1, y: 0 }}
                            exit={{ opacity: 0, y: -50 }}
                            transition={{ duration: 0.3 }}
                          >
                            <div className="flex items-center space-x-2 dark:text-white">
                              <Lock className="text-gray-400" size={20} />
                              <Input
                                type="password"
                                placeholder="Password"
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                required
                              />
                            </div>
                          </motion.div>
                        )}
                      </AnimatePresence>
                      {errorMessage && (
                        <p className="text-red-500 text-sm">{errorMessage}</p>
                      )}
                      <Button type="submit" className="w-full" disabled={isLoading}>
                        {isLoading ? (
                          <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                        ) : null}
                        {showPassword ? 'Log in' : 'Next'}
                      </Button>
                    </form>
                    <Button
                      variant="link"
                      className="w-full mt-4"
                      onClick={() => setShowForgotPassword(true)}
                    >
                      Forgot Password?
                    </Button>
                  </motion.div>
                )}
              </AnimatePresence>
            </TabsContent>
            <TabsContent value="register">
          <AnimatePresence mode="wait">
            {renderRegistrationStep()}
          </AnimatePresence>
          {errorMessage && (
                <p className="text-red-500 text-sm mt-2">{errorMessage}</p>
              )}
        </TabsContent>
        </Tabs>
    </div>
  </div>
  </div>
);
};

export default AuthComponent;