import React, { useRef, useEffect, useState, useCallback, useLayoutEffect } from 'react';
import { Search, Settings, Edit, Moon, Sun, Menu, Save, Lightbulb, X, FileText, Headphones, Type, Book, Feather, Sparkles, PlusCircle, Trash2, Phone, User, Clock, Calendar, ArrowRight, LogOut, Tags, Loader2, HandPlatter, RefreshCw, Hand, HeartCrack, CornerUpLeft, LibraryBig } from 'lucide-react';
import { Input } from "./components/ui/input"
import { Button } from "./components/ui/button"
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./components/ui/tabs"
import { Switch } from "./components/ui/switch"
import { Sheet, SheetContent, SheetTrigger } from "./components/ui/sheet"
import CustomAudioPlayer from './components/CustomAudioPlayer';
import SettingsModal from './components/SettingsModal';
import MarkdownEditor from 'react-markdown-editor-lite';
import MarkdownIt from 'markdown-it';
import 'react-markdown-editor-lite/lib/index.css';
import { format, parseISO, isSameMonth } from 'date-fns';
import AuthComponent from './components/auth';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "./components/ui/dialog"
import { AlertDialog, AlertDialogContent, AlertDialogHeader, AlertDialogTitle, AlertDialogDescription, AlertDialogFooter, AlertDialogCancel, AlertDialogAction } from "./components/ui/alert-dialog";
import { debounce } from 'lodash';
import { motion, AnimatePresence } from 'framer-motion';
import ReactConfetti from 'react-confetti';
import { loadStripe } from '@stripe/stripe-js';
import { useInfiniteQuery, useQueryClient } from 'react-query';

// Initialize a markdown parser
const mdParser = new MarkdownIt();

// make apiURL a variable available to the entire component
// const apiURL = 'http://localhost:5000';
const apiURL = 'https://api.callreverie.com';

const stripePromise = loadStripe('pk_live_51LZK81EVmyPNhExzHXm1BF3uC8BO6iTdg6R3hrrcjo3XEnq6fral763hSpQppYgCnRzNZO6zeCVHdq2aJ835k37e00Vhl33gOG');

const JournalEntry = React.forwardRef(({ entry, onSelect, isSelected, id }, ref) => (
  <div 
    id={id}
    ref={ref}
    className={`p-2 cursor-pointer rounded ${
      isSelected 
        ? 'bg-white border border-gray-300 dark:bg-black text-black dark:text-white' 
        : 'hover:bg-gray-100 dark:hover:border dark:hover:border-gray-700 dark:hover:bg-black dark:hover:text-white'
    }`}
    onClick={() => onSelect(entry)}
  >
    <h3 className={`font-bold ${isSelected ? 'text-black dark:text-white' : 'dark:text-white'}`}>
      {entry.title}
    </h3>
    <p className="text-sm text-gray-500 dark:text-gray-400">
      {formatDate(entry.date)}
    </p>
  </div>
));

JournalEntry.displayName = 'JournalEntry';

const MonthHeader = ({ date }) => (
  <div className="sticky top-0 bg-white dark:bg-black p-2 font-semibold text-sm text-gray-600 dark:text-gray-300">
    {format(date, "MMMM yyyy")}
  </div>
);

const formatDate = (dateString) => {
  try {
    const date = parseISO(dateString);
    return format(date, "MMMM do yyyy 'at' h:mma");
  } catch (error) {
    console.error('Error parsing date:', dateString, error);
    return dateString; // Return original string if parsing fails
  }
};

const handleCallNow = async () => {
  try {
    const response = await fetch(apiURL + '/api/call-now', {
      method: 'POST',
      credentials: 'include'
    });

    if (!response.ok) {
      throw new Error('Failed to initiate call');
    }

    const data = await response.json();
    alert(data.message); // You might want to replace this with a more user-friendly notification
  } catch (error) {
    console.error('Error initiating call:', error);
    alert('Failed to initiate call. Please try again.'); // Replace with a user-friendly error message
  }
};

const SelectEntryPrompt = () => (
  <div className="flex flex-col items-center justify-center h-full text-center p-8 bg-gray-50 dark:bg-gray-900 rounded-lg shadow-inner">
    <LibraryBig className="w-16 h-16 text-black dark:text-white mb-4" />
    <h2 className="text-2xl font-bold mb-2 text-gray-800 dark:text-gray-200">
      Select an Entry
    </h2>
    <p className="text-lg text-gray-600 dark:text-gray-400 max-w-md">
      Choose an entry from the sidebar to enjoy it here.
    </p>
  </div>
);

const NoEntriesMessage = () => (
  <div className="flex flex-col items-center justify-center h-full text-center p-4">
    <HandPlatter className="h-12 w-12 mb-4 text-gray-400 dark:text-gray-600" />
    <p className="text-sm text-gray-500 dark:text-gray-400">
      Your entries will appear here after your first call.
    </p>
  </div>
);

const WelcomeMessage = ({ handleCallNow }) => {
  return (
    <div className="flex flex-col items-center justify-center h-full text-center p-8">
      
      <h1 className="text-4xl font-bold mb-6 reverie-font">
        Welcome to Reverie
      </h1>
      <div className="flex justify-center space-x-12 mb-8">
        <div className="flex flex-col items-center">
          <Phone className="h-12 w-12 mb-2 text-black dark:text-white" />
          <span className="text-lg">Record</span>
        </div>
        <div className="flex flex-col items-center">
          <Book className="h-12 w-12 mb-2 text-black dark:text-white" />
          <span className="text-lg">Revisit</span>
        </div>
        <div className="flex flex-col items-center">
          <Sparkles className="h-12 w-12 mb-2 text-black dark:text-white" />
          <span className="text-lg">Reflect</span>
        </div>
      </div>
      <div className="relative border border-gray-300 dark:border-gray-700 rounded-lg p-4 mb-8 inline-block">
        <div className="absolute -top-3 left-4 bg-white dark:bg-black px-2 py-1 rounded-full flex items-center space-x-1 text-sm font-medium text-gray-600 dark:text-gray-400 border border-gray-300 dark:border-gray-700">
          <Lightbulb className="h-4 w-4" />
          <span>Tip</span>
        </div>
        <h3 className="font-semibold mb-2">Add Reverie as a Contact</h3>
        <p className="text-lg text-gray-500 dark:text-gray-500">
          Reverie will call you from <span className="font-semibold dark:text-white">+1 (888) 204-5735</span>
        </p>
      </div>
      <p className="text-xl max-w-2xl mb-6 text-gray-600 dark:text-gray-400">
        You'll be able to explore your thoughts, dreams, and experiences here - after your first call.
      </p>
    </div>
  );
};

const Tag = ({ tag, onSelect, selected }) => (
  <span 
    className={`inline-block px-2 py-1 rounded text-sm mr-2 mb-2 cursor-pointer ${
      selected ? 'bg-black text-white' : 'bg-gray-200 text-gray-700 hover:bg-gray-300'
    }`}
    onClick={() => onSelect(tag)}
  >
    {tag}
  </span>
);

const Sidebar = ({ searchTerm, setSearchTerm, handleEntrySelect, tags, selectedTag, handleTagSelect, searchInputRef, selectedEntry, refreshEntries, scrollContainerRef, scrollPosition, fetchEntries }) => {
  useLayoutEffect(() => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollTop = scrollPosition;
    }
  }, [scrollPosition]);

  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
  };

  return (
    <div className="h-full flex flex-col">
      <div className="mb-4">
        <div className="flex items-center">
          <div className="relative flex-grow">
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" />
            <Input
              ref={searchInputRef}
              type="text"
              placeholder="Search entries..."
              onChange={handleSearchChange}
              className="pl-8 pr-10 dark:bg-black dark:text-white"
              defaultValue={searchTerm}
            />
          </div>
          <Button 
            onClick={refreshEntries} 
            variant="ghost" 
            size="icon" 
            className="ml-2"
          >
            <RefreshCw className="h-4 w-4" />
          </Button>
        </div>
      </div>
      {selectedTag && (
        <div className="mb-4">
          <Tag tag={selectedTag} onSelect={handleTagSelect} selected={true} />
        </div>
      )}
      <div className="flex-grow overflow-y-auto" ref={scrollContainerRef}>
        <InfiniteScrollEntries
          searchTerm={searchTerm}
          selectedTag={selectedTag}
          handleEntrySelect={handleEntrySelect}
          fetchEntries={fetchEntries}
        />
      </div>
    </div>
  );
};

const InfoPopup = ({ onClose }) => {
  return (
    <motion.div
      initial={{ opacity: 0, scale: 0.8 }}
      animate={{ opacity: 1, scale: 1 }}
      exit={{ opacity: 0, scale: 0.8 }}
      className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50"
    >
      <div className="bg-white dark:bg-gray-800 p-8 rounded-lg max-w-md w-full">
        <Sparkles className="h-16 w-16 mb-4 text-yellow-400 mx-auto" />
        <h2 className="text-3xl font-semibold text-center mb-4 reverie-font">Welcome to Reverie!</h2>
        <span className="text-md text-gray-600 dark:text-gray-400 block text-center mb-4">
          Your subscription is now active.
        </span>
        <div className="space-y-4 mb-6">
          <div className="flex items-start space-x-4">
            <Phone className="flex-shrink-0 w-6 h-6 text-black" />
            <p>Reverie will call you for quick check-ins.</p>
          </div>
          <div className="flex items-start space-x-4">
            <Clock className="flex-shrink-0 w-6 h-6 text-black" />
            <p>Each call lasts up to 10 minutes, perfect for capturing your thoughts.</p>
          </div>
          <div className="flex items-start space-x-4">
            <Calendar className="flex-shrink-0 w-6 h-6 text-black" />
            <p>You've already set up your preferred call schedule.</p>
          </div>
        </div>
        <Button onClick={onClose} className="w-full bg-black text-white">
          Explore Dashboard <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </div>
    </motion.div>
  );
};

const InfiniteScrollEntries = ({ searchTerm, selectedTag, handleEntrySelect, fetchEntries }) => {
  const [selectedEntryId, setSelectedEntryId] = useState(null);
  const lastEntryRef = useRef(null);

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
  } = useInfiniteQuery(
    ['entries', searchTerm, selectedTag],
    fetchEntries,
    {
      getNextPageParam: (lastPage) => lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined,
    }
  );

  const loadMore = useCallback(() => {
    if (hasNextPage && !isFetchingNextPage) {
      fetchNextPage();
    }
  }, [fetchNextPage, hasNextPage, isFetchingNextPage]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          loadMore();
        }
      },
      { threshold: 0.1 }
    );

    if (lastEntryRef.current) {
      observer.observe(lastEntryRef.current);
    }

    return () => observer.disconnect();
  }, [loadMore, data]);
  
    // Check if there are no entries
    if (data?.pages[0]?.entries.length === 0) {
      return <NoEntriesMessage />;
    }

  const handleEntryClick = (entry) => {
    setSelectedEntryId(entry._id);
    handleEntrySelect(entry);
  };

  if (status === 'loading') return <div className='max-w flex items-center justify-center'><Loader2 className="h-8 w-8 animate-spin center" /></div>;
  if (status === 'error') return <div>Error fetching entries</div>;

  const displayedMonths = new Set();

  return (
    <div className="overflow-y-auto h-full">
      {data?.pages.map((page, pageIndex) => (
        <React.Fragment key={pageIndex}>
          {page.entries.map((entry, entryIndex) => {
            const entryDate = parseISO(entry.date);
            const monthYear = format(entryDate, 'MMMM yyyy');
            const isLastEntry = pageIndex === data.pages.length - 1 && entryIndex === page.entries.length - 1;
            const showHeader = !displayedMonths.has(monthYear);
            
            if (showHeader) {
              displayedMonths.add(monthYear);
            }

            return (
              <React.Fragment key={entry._id}>
                {showHeader && <MonthHeader date={entryDate} />}
                <JournalEntry
                  entry={entry}
                  onSelect={handleEntryClick}
                  isSelected={entry._id === selectedEntryId}
                  id={isLastEntry ? 'last-entry' : undefined}
                  ref={isLastEntry ? lastEntryRef : null}
                />
              </React.Fragment>
            );
          })}
        </React.Fragment>
      ))}
      {isFetchingNextPage && <div className='max-w flex items-center justify-center'><Loader2 className="h-8 w-8 animate-spin" /></div>}
      {hasNextPage && !isFetchingNextPage && (
        <Button onClick={loadMore} className="w-full mt-4">
          Load More
        </Button>
      )}
    </div>
  );
};

const ReverieJournalApp = () => {
  const [selectedEntry, setSelectedEntry] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [darkMode, setDarkMode] = useState(false);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [editedContent, setEditedContent] = useState("");
  const [activeTab, setActiveTab] = useState("formatted");
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isEntryLoading, setIsEntryLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [tags, setTags] = useState([]);
  const [selectedTag, setSelectedTag] = useState(null);
  const [showInfoPopup, setShowInfoPopup] = useState(false);
  const [editedTags, setEditedTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [showTagSection, setShowTagSection] = useState(false);
  const [isSubscribed, setIsSubscribed] = useState(true);
  const [showResubscribeModal, setShowResubscribeModal] = useState(false);

  const searchInputRef = useRef(null);
  const scrollContainerRef = useRef(null);
  const [scrollPosition, setScrollPosition] = useState(0);

  const queryClient = useQueryClient();

  const fetchEntries = async ({ pageParam = 1 }) => {
    const response = await fetch(`${apiURL}/api/entries?page=${pageParam}&search=${searchTerm}&tag=${selectedTag || ''}`, {
      credentials: 'include'
    });
    if (!response.ok) {
      throw new Error('Failed to fetch entries');
    }
    return response.json();
  };

  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    status,
    refetch
  } = useInfiniteQuery(
    ['entries', searchTerm, selectedTag],
    fetchEntries,
    {
      getNextPageParam: (lastPage) => lastPage.currentPage < lastPage.totalPages ? lastPage.currentPage + 1 : undefined,
    }
  );

  const refreshEntries = async () => {
    await queryClient.resetQueries(['entries', searchTerm, selectedTag]);
  };

  const handleTagSelect = useCallback((tag) => {
    setSelectedTag(tag === selectedTag ? null : tag);
    queryClient.resetQueries(['entries', searchTerm, selectedTag]);
    
    if (window.innerWidth < 1024) {
      handleSidebarOpen(true);
    }
  }, [selectedTag, queryClient, searchTerm]);

  const handleSearch = useCallback(debounce((value) => {
    setSearchTerm(value);
    queryClient.resetQueries(['entries', searchTerm, selectedTag]);
  }, 300), [queryClient, selectedTag]);

  useEffect(() => {
    if (isAuthenticated) {
      checkSubscriptionStatus();
    }
  }, [isAuthenticated]);

  const checkSubscriptionStatus = async () => {
    try {
      const response = await fetch(apiURL + '/api/user/subscription-status', {
        credentials: 'include'
      });
      const data = await response.json();
      setIsSubscribed(data.isSubscribed);
      console.log('Subscription status:', data.isSubscribed);
      if (!data.isSubscribed) {
        console.log('Subscription expired');
        setShowResubscribeModal(true);
      }
    } catch (error) {
      console.error('Error checking subscription status:', error);
    }
  };

  const handleResubscribe = async () => {
    try {
      const response = await fetch(apiURL + '/api/resubscribe', {
        method: 'POST',
        credentials: 'include'
      });
      if (response.ok) {
        const stripe = await stripePromise;
        const { sessionId } = await response.json();
        const { error } = await stripe.redirectToCheckout({ sessionId });
        if (error) {
          console.error('Stripe redirect error:', error);
          // Handle the error, maybe show an error message to the user
        }
      } else {
        // Handle error
        console.error('Failed to resubscribe');
        // Maybe show an error message to the user
      }
    } catch (error) {
      console.error('Error resubscribing:', error);
      // Handle the error, show an error message to the user
    }
  };

  const ResubscribeModal = () => (
    <Dialog open={showResubscribeModal} onOpenChange={setShowResubscribeModal}>
      <DialogContent>
      <HeartCrack className="h-8 w-8 text-red-500" />
        <DialogHeader>
          <DialogTitle>Subscription Expired</DialogTitle>
        </DialogHeader>
        <p>Your subscription has expired. You can still access your existing entries, but you won't receive any more calls. Would you like to resubscribe?</p>
        <DialogFooter>
          <Button onClick={handleResubscribe}>Resubscribe</Button>
          <Button variant="outline" onClick={() => setShowResubscribeModal(false)}>Not Now</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');
    if (sessionId) {
      handlePaymentSuccess(sessionId);
    }
  }, []);

  const handlePaymentSuccess = async (sessionId) => {
    try {
      const response = await fetch(apiURL + `/api/payment-success?session_id=${sessionId}`, {
        credentials: 'include'
      });
      if (response.ok) {
        setIsAuthenticated(true);
        setShowInfoPopup(true);
        // Remove the session_id from the URL
        window.history.replaceState({}, document.title, window.location.pathname);
      } else {
        throw new Error('Failed to verify payment');
      }
    } catch (error) {
      console.error('Error handling payment success:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleSidebarOpen = useCallback((open) => {
    setSidebarOpen(open);
    if (open && window.innerWidth < 1024) {
      // Delay to ensure the sidebar is fully opened before we blur
      setTimeout(() => {
        if (searchInputRef.current) {
          searchInputRef.current.blur();
        }
        // Force the keyboard to close
        if (document.activeElement instanceof HTMLElement) {
          document.activeElement.blur();
        }
      }, 10);
    }
  }, []);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1024) {
        setSidebarOpen(false);
      }
    };
  
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const handleEntrySelect = async (entry) => {
    setIsEntryLoading(true);
    setError(null);
    try {
      const response = await fetch(apiURL + `/api/entries/${entry._id}`, {
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to fetch entry details');
      }
      const fullEntry = await response.json();
      setSelectedEntry(fullEntry);
      setEditedContent(fullEntry.formatted || '');
      setSidebarOpen(false);
      setIsEditing(false);
    } catch (error) {
      console.error('Error fetching entry details:', error);
      setError('Failed to load entry details. Please try again.');
      setSelectedEntry(null);
    } finally {
      setIsEntryLoading(false);
    }
  };

  const renderMarkdown = (content) => {
    if (typeof content !== 'string') {
      console.error('Invalid markdown content:', content);
      return '';
    }
    try {
      return mdParser.render(content);
    } catch (error) {
      console.error('Error rendering markdown:', error);
      return 'Error rendering content';
    }
  };

  const handleLogin = () => {
    setIsAuthenticated(true);
  };

  const checkAuthStatus = async () => {
    try {
      const response = await fetch(apiURL + '/api/protected', {
        credentials: 'include'
      });
      if (response.ok) {
        setIsAuthenticated(true); 
      }
    } catch (error) {
      console.error('Error checking auth status:', error);
    }
  };

  const handleLogout = async () => {
    try {
      await fetch(apiURL + '/api/logout', {
        method: 'POST',
        credentials: 'include'
      });
      setIsAuthenticated(false);
      setSelectedEntry(null);
      queryClient.removeQueries(['entries']);
    } catch (error) {
      console.error('Error logging out:', error);
    }
  };

  if (!isAuthenticated) {
    return <AuthComponent onLogin={handleLogin} />;
  }

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
    document.documentElement.classList.toggle('dark');
  };
  
  const openSettings = () => {
    setSettingsOpen(true);
  };

  const handleDelete = async () => {
    if (!selectedEntry) return;

    try {
      const response = await fetch(apiURL + `/api/entries/${selectedEntry._id}`, {
        method: 'DELETE',
        credentials: 'include'
      });

      if (!response.ok) {
        throw new Error('Failed to delete entry');
      }

      queryClient.invalidateQueries(['entries']);
      setSelectedEntry(null);
      setIsDeleteDialogOpen(false);
    } catch (error) {
      console.error('Error deleting entry:', error);
      setError('Failed to delete entry. Please try again.');
    }
  };

  const handleEdit = () => {
    setIsEditing(true);
    setEditedTags([...selectedEntry.tags]);
  };

  const handleSave = async () => {
    try {
      const response = await fetch(apiURL + `/api/entries/${selectedEntry._id}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          ...selectedEntry,
          formatted: editedContent,
          tags: editedTags
        }),
        credentials: 'include'
      });
      if (!response.ok) {
        throw new Error('Failed to update entry');
      }
      const updatedEntry = await response.json();
      setSelectedEntry(updatedEntry);
      queryClient.invalidateQueries(['entries']);
      setIsEditing(false);
    } catch (error) {
      console.error('Error updating entry:', error);
      setError('Failed to update entry. Please try again.');
    }
  };

  const handleAddTag = () => {
    if (newTag && !editedTags.includes(newTag)) {
      setEditedTags([...editedTags, newTag]);
      setNewTag('');
    }
  };

  const handleRemoveTag = (tagToRemove) => {
    setEditedTags(editedTags.filter(tag => tag !== tagToRemove));
  };

  const handleCancel = () => {
    setEditedContent(selectedEntry.formatted);
    setIsEditing(false);
  };

  const handleEditorChange = ({ text }) => {
    setEditedContent(text);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  const toggleTagSection = () => {
    setShowTagSection(!showTagSection);
  };

  return (
    <div className={`flex flex-col h-screen ${darkMode ? 'dark' : ''}`}>
      <div className="flex flex-col min-h-screen w-full bg-white dark:bg-black text-black dark:text-white">
        {/* Top bar with logo, theme toggle, menu button, and logout */}
        <div className="relative flex justify-between bg-black items-center p-4 border-b text-white dark:border-gray-700 header-container">
          <Sheet open={sidebarOpen} onOpenChange={handleSidebarOpen}>
            <SheetTrigger asChild>
              <Button variant="outline" size="icon" className="lg:hidden text-black dark:text-white" style={{zIndex: 2}}>
                <Button 
                  variant="outline" 
                  size="icon" 
                  className="lg:hidden text-black dark:text-white" 
                  style={{zIndex: 2}}
                  onClick={(e) => {
                    e.preventDefault();
                    handleSidebarOpen(true);
                  }}
                >
                  <Menu className="h-4 w-4" />
                </Button>
              </Button>
            </SheetTrigger>
            <SheetContent side="left" className="w-[80%] sm:w-[385px] p-0">
              <div className="h-full p-4 flex flex-col dark:bg-black">
                <div className="flex-1" style={{overflow: 'hidden'}}>
                  <Sidebar
                    searchTerm={searchTerm}
                    setSearchTerm={setSearchTerm}
                    handleEntrySelect={handleEntrySelect}
                    tags={tags}
                    selectedTag={selectedTag}
                    handleTagSelect={handleTagSelect}
                    searchInputRef={searchInputRef}
                    selectedEntry={selectedEntry}
                    refreshEntries={refreshEntries}
                    scrollContainerRef={scrollContainerRef}
                    scrollPosition={scrollPosition}
                    fetchEntries={fetchEntries}
                  />
                </div>
                <div className="mt-4">
                  <div className="m-4 flex items-center justify-between">
                    <Sun className="h-4 w-4 mr-2 dark:text-white" />
                    <Switch checked={darkMode} onCheckedChange={toggleDarkMode} />
                    <Moon className="h-4 w-4 ml-2 dark:text-white" />
                  </div>
                  <Button onClick={openSettings} variant="outline" className="w-full dark:bg-black dark:hover:bg-gray-900 dark:text-white">
                    <Settings className="mr-2 h-4 w-4" /> Settings
                  </Button>
                  <Button onClick={handleLogout} variant="outline" className="w-full mt-2 dark:bg-black dark:hover:bg-gray-900 dark:text-white">
                    <LogOut className="mr-2 h-4 w-4" /> Logout
                  </Button>
                </div>
              </div>
            </SheetContent>
          </Sheet>
          <h1 className="text-2xl font-bold font-['Playfair_Display'] absolute left-1/2 transform -translate-x-1/2">
            Reverie
          </h1>
          <div className="hidden lg:flex items-center" style={{zIndex: 2}}>
            <Sun className="h-4 w-4 mr-2" />
            <Switch checked={darkMode} onCheckedChange={toggleDarkMode} />
            <Moon className="h-4 w-4 ml-2" />
            <Button 
              onClick={handleLogout} 
              variant="outline" 
              size="sm" 
              className="ml-4 text-black dark:text-white group relative"
            >
              <LogOut className="h-4 w-4" />
              <span className="absolute left-full ml-2 whitespace-nowrap bg-black text-white px-2 py-1 rounded opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                Logout
              </span>
            </Button>
          </div>
        </div>

        {/* Main content area */}
        <div className="flex flex-1 overflow-hidden">
          {/* Left Sidebar - hidden on mobile, visible on larger screens */}
          <div className="hidden lg:flex lg:flex-col lg:w-1/4 border-r dark:border-gray-700">
            <div className="flex flex-col h-full">
              <div className="flex-1 overflow-y-auto p-4">
                <Sidebar
                  searchTerm={searchTerm}
                  setSearchTerm={setSearchTerm}
                  handleEntrySelect={handleEntrySelect}
                  tags={tags}
                  selectedTag={selectedTag}
                  handleTagSelect={handleTagSelect}
                  searchInputRef={searchInputRef}
                  selectedEntry={selectedEntry}
                  refreshEntries={refreshEntries}
                  scrollContainerRef={scrollContainerRef}
                  scrollPosition={scrollPosition}
                  fetchEntries={fetchEntries}
                />
              </div>
              <div className="p-4 border-t dark:border-gray-700 bg-white dark:bg-black">
                <Button onClick={openSettings} variant="outline" className="w-full dark:bg-black dark:hover:bg-gray-900">
                  <Settings className="mr-2 h-4 w-4" /> Settings
                </Button>
              </div>
            </div>
          </div>

          {/* Main Content */}
          <div className="flex-1 overflow-y-auto">
            {isEntryLoading ? (
              <div className="flex items-center justify-center h-full">
                <Loader2 className="h-8 w-8 animate-spin" />
              </div>
            ) : selectedEntry ? (
              <Tabs 
                value={activeTab}
                onValueChange={setActiveTab}
                className="flex flex-col h-full"
              >
                <div className="flex justify-between items-center p-4">
                  <div className="block mt-3 ml-5">
                    <h2 className="text-xl font-bold" style={{lineHeight: '1.25rem'}}>{selectedEntry.title}</h2>
                    <p className="text-sm text-gray-500 dark:text-gray-400 mt-1">{formatDate(selectedEntry.date)}</p>
                  </div>
                  <div className="flex items-center space-x-4">
                    {activeTab === "formatted" && (
                      isEditing ? (
                        <div style={{minWidth: '80px'}}>
                          <Button onClick={() => setIsDeleteDialogOpen(true)} variant="destructive" style={{margin: '0.25rem', padding: '0.25rem'}}>
                            <Trash2 className="h-4 w-4" />
                          </Button>
                          <Button onClick={handleSave} style={{margin: '0.25rem', padding: '0.25rem'}}>
                            <Save className="h-4 w-4" />
                          </Button>
                          <Button onClick={toggleTagSection} style={{margin: '0.25rem', padding: '0.25rem'}}>
                            <Tags className="h-4 w-4" />
                          </Button>
                          <Button onClick={handleCancel} variant="outline" style={{margin: '0.25rem', padding: '0.25rem'}}>
                            <X className="h-4 w-4" />
                          </Button>
                        </div>
                      ) : (
                        <div>
                          <Button onClick={handleEdit} style={{margin: '0.25rem', padding: '0.25rem 0.7rem 0.25rem 0.7rem',}}>
                            <Edit className="h-4 w-4"  />
                          </Button>
                        </div>
                      )
                    )}
                    <TabsList className="dark:bg-black">
                      <TabsTrigger value="formatted" className="px-2">
                        <FileText className="h-4 w-4" />
                      </TabsTrigger>
                      <TabsTrigger value="raw" className="px-2">
                        <Type className="h-4 w-4" />
                      </TabsTrigger>
                      <TabsTrigger value="audio" className="px-2">
                        <Headphones className="h-4 w-4" />
                      </TabsTrigger>
                    </TabsList>
                  </div>
                </div>
                <div className="flex-grow overflow-hidden">
                  <TabsContent value="formatted" className="h-full">
                    {isEditing ? (
                      <div className="h-full">
                        <div className="mb-4 ml-5">
                          {showTagSection && (
                            <>
                              <h3 className="text-lg font-semibold mb-2">Tags</h3>
                              <div className="flex flex-wrap gap-2 mb-2">
                                {editedTags.map((tag, index) => (
                                  <span key={index} className="bg-gray-200 dark:bg-gray-700 px-2 py-1 rounded flex items-center">
                                    {tag}
                                    <Button
                                      onClick={() => handleRemoveTag(tag)}
                                      variant="ghost"
                                      size="sm"
                                      className="ml-1 p-0 h-4 w-4"
                                    >
                                      <X className="h-3 w-3" />
                                    </Button>
                                  </span>
                                ))}
                              </div>
                              <div className="flex items-center mr-10">
                                <Input
                                  type="text"
                                  value={newTag}
                                  onChange={(e) => setNewTag(e.target.value)}
                                  placeholder="Add a new tag"
                                  className="mr-2 dark:text-white"
                                />
                                <Button onClick={handleAddTag} size="sm">
                                  <PlusCircle className="mr-2 h-4 w-4" /> Add Tag
                                </Button>
                              </div>
                            </>
                          )}
                        </div>
                        <MarkdownEditor
                          value={editedContent}
                          onChange={handleEditorChange}
                          renderHTML={(text) => renderMarkdown(text)}
                          className="h-full"
                          view={{ menu: true, md: true, html: true }}
                          style={{height: showTagSection ? 'calc(100vh - 340px)' : 'calc(100vh - 200px)'}}
                        />
                      </div>
                    ) : (
                      <div className="prose dark:prose-invert w-med ml-5 overflow-y-auto h-full p-4 border-t-2 border-gray">
                        <div 
                          dangerouslySetInnerHTML={{ __html: renderMarkdown(selectedEntry.formatted || '') }}
                          className="markdown-content"
                        />
                        <div className="p-1 flex flex-wrap">
                          {selectedEntry.tags.map(tag => (
                            <Tag key={tag} tag={tag} onSelect={handleTagSelect} />
                          ))}
                        </div>
                      </div>
                    )}
                  </TabsContent>
                  <TabsContent value="raw" className="h-full">
                    <pre className="bg-gray-100 dark:bg-black p-4 rounded overflow-y-auto h-full whitespace-pre-wrap break-words">{selectedEntry.transcript}</pre>
                  </TabsContent>
                  <TabsContent value="audio" className="h-full">
                    <CustomAudioPlayer src={selectedEntry.audioUrl} />
                  </TabsContent>
                </div>
              </Tabs>
) : status === 'loading' ? (
  <div className="flex items-center justify-center h-full">
    <Loader2 className="h-8 w-8 animate-spin" />
  </div>
) : data?.pages[0]?.entries.length > 0 ? (
  <SelectEntryPrompt />
) : (
  <WelcomeMessage handleCallNow={handleCallNow} />
)}
</div>
        </div>
      </div>
      <ResubscribeModal />
      <SettingsModal isOpen={settingsOpen} onClose={() => setSettingsOpen(false)} handleResubscribe={handleResubscribe} />
      <AlertDialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <AlertDialogContent>
          <AlertDialogHeader>
            <AlertDialogTitle>Are you sure you want to delete this entry?</AlertDialogTitle>
            <AlertDialogDescription>
              This action cannot be undone. This will permanently delete your entry.
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel>Cancel</AlertDialogCancel>
            <AlertDialogAction onClick={handleDelete}>Delete</AlertDialogAction>
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
      {showInfoPopup && <InfoPopup onClose={() => setShowInfoPopup(false)} />}
      {showInfoPopup && <ReactConfetti />}
    </div>
  );
};

export default ReverieJournalApp;