import React, { useState, useRef, useEffect } from 'react';
import { Play, Pause, RotateCcw, RotateCw, Volume2, VolumeX, AlertCircle, Loader } from 'lucide-react';
import { Slider } from "./ui/slider"
import { Button } from "./ui/button"
import { Alert, AlertDescription } from "./ui/alert"

const formatTime = (time) => {
  if (isNaN(time)) return "0:00";
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  return `${minutes}:${seconds.toString().padStart(2, '0')}`;
};

const CustomAudioPlayer = ({ src }) => {
  const audioRef = useRef(new Audio());
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);
  const [volume, setVolume] = useState(1);
  const [isMuted, setIsMuted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [isBuffering, setIsBuffering] = useState(false);

  useEffect(() => {
    const audio = audioRef.current;

    const handleTimeUpdate = () => setCurrentTime(audio.currentTime);
    const handleDurationChange = () => setDuration(audio.duration);
    const handleEnded = () => setIsPlaying(false);
    const handlePlay = () => setIsPlaying(true);
    const handlePause = () => setIsPlaying(false);
    const handleWaiting = () => setIsBuffering(true);
    const handleCanPlay = () => setIsBuffering(false);
    const handleError = (e) => {
      console.error('Audio playback error:', e);
      setError(`Playback error: ${e.message}`);
      setIsPlaying(false);
    };

    audio.addEventListener('timeupdate', handleTimeUpdate);
    audio.addEventListener('durationchange', handleDurationChange);
    audio.addEventListener('ended', handleEnded);
    audio.addEventListener('play', handlePlay);
    audio.addEventListener('pause', handlePause);
    audio.addEventListener('waiting', handleWaiting);
    audio.addEventListener('canplay', handleCanPlay);
    audio.addEventListener('error', handleError);

    // Sync initial state
    setIsPlaying(!audio.paused);
    setCurrentTime(audio.currentTime);
    setDuration(audio.duration);
    setVolume(audio.volume);
    setIsMuted(audio.muted);

    return () => {
      audio.removeEventListener('timeupdate', handleTimeUpdate);
      audio.removeEventListener('durationchange', handleDurationChange);
      audio.removeEventListener('ended', handleEnded);
      audio.removeEventListener('play', handlePlay);
      audio.removeEventListener('pause', handlePause);
      audio.removeEventListener('waiting', handleWaiting);
      audio.removeEventListener('canplay', handleCanPlay);
      audio.removeEventListener('error', handleError);
      audio.pause();
      URL.revokeObjectURL(audio.src);
    };
  }, []);

  useEffect(() => {
    if (src) {
      let recordingId;
      if (src.startsWith('http')) {
        const match = src.match(/\/Recordings\/([A-Za-z0-9]+)/);
        recordingId = match ? match[1] : null;
      } else {
        recordingId = src.replace(/\.[^/.]+$/, "");
      }

      if (recordingId) {
        fetchAudio(recordingId);
      } else {
        console.error('Invalid audio source:', src);
        setError('Invalid audio source');
      }
    }
  }, [src]);

  const fetchAudio = async (recordingId) => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`https://api.callreverie.com/api/audio/${recordingId}`, {
        credentials: 'include'
      });
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      
      const audioDuration = response.headers.get('X-Audio-Duration');
      setDuration(parseFloat(audioDuration));

      const blob = await response.blob();
      const url = URL.createObjectURL(blob);
      audioRef.current.src = url;

      // Wait for the audio to be loaded before setting isLoading to false
      audioRef.current.onloadedmetadata = () => {
        setIsLoading(false);
      };

      audioRef.current.onerror = (e) => {
        console.error('Error loading audio:', e);
        setError(`Failed to load audio: ${e.message}`);
        setIsLoading(false);
      };

    } catch (error) {
      console.error('Error fetching audio:', error);
      setError(`Failed to load audio: ${error.message}`);
      setIsLoading(false);
    }
  };

  const togglePlay = () => {
    const audio = audioRef.current;
    if (isPlaying) {
      audio.pause();
    } else {
      audio.play().catch(e => {
        console.error('Playback failed:', e);
        setError(`Playback failed: ${e.message}`);
      });
    }
  };

  const handleSeek = (newValue) => {
    const [newTime] = newValue;
    audioRef.current.currentTime = newTime;
    setCurrentTime(newTime);
  };

  const handleVolumeChange = (newValue) => {
    const [newVolume] = newValue;
    audioRef.current.volume = newVolume;
    setVolume(newVolume);
    setIsMuted(newVolume === 0);
  };

  const toggleMute = () => {
    const audio = audioRef.current;
    audio.muted = !audio.muted;
    setIsMuted(audio.muted);
  };

  const skip = (seconds) => {
    const newTime = audioRef.current.currentTime + seconds;
    audioRef.current.currentTime = Math.max(0, Math.min(newTime, duration));
  };

  return (
    <div className="bg-white dark:bg-black rounded-lg shadow-lg p-6 w-full">
      {error && (
        <Alert variant="destructive" className="mb-4">
          <AlertCircle className="h-4 w-4" />
          <AlertDescription>{error}</AlertDescription>
        </Alert>
      )}
      {isLoading ? (
        <div className="flex justify-center items-center h-32">
          <Loader className="h-8 w-8 animate-spin text-gray-500 dark:text-gray-400" />
        </div>
      ) : (
        <>
          <div className="flex justify-center items-center mb-6 space-x-4">
            <Button variant="outline" size="icon" className="rounded-full" onClick={() => skip(-10)}>
              <RotateCcw className="h-4 w-4" />
            </Button>
            <Button variant="outline" size="icon" className="rounded-full w-12 h-12" onClick={togglePlay}>
              {isPlaying ? <Pause className="h-6 w-6" /> : <Play className="h-6 w-6" />}
            </Button>
            <Button variant="outline" size="icon" className="rounded-full" onClick={() => skip(10)}>
              <RotateCw className="h-4 w-4" />
            </Button>
          </div>
          <div className="mb-4">
            <Slider
              value={[currentTime]}
              max={duration || 100}
              step={0.1}
              onValueChange={handleSeek}
            />
            <div className="flex justify-between text-sm mt-1">
              <span>{formatTime(currentTime)}</span>
              <span>{formatTime(duration)}</span>
            </div>
          </div>
          <div className="flex flex-col items-center">
            <Button variant="ghost" size="icon" onClick={toggleMute}>
              {isMuted ? <VolumeX className="h-4 w-4" /> : <Volume2 className="h-4 w-4" />}
            </Button>
            <Slider
              className="mt-2 max-w-[200px]"
              value={[isMuted ? 0 : volume]}
              max={1}
              step={0.01}
              onValueChange={handleVolumeChange}
            />
          </div>
          {isBuffering && (
            <div className="text-center text-sm text-gray-500 mt-2">
              Buffering...
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default CustomAudioPlayer;